import React from 'react';
import classnames from 'classnames';

import './style.less';

function Button({
  children,
  className,
  color,
  handleClick,
  size,
  prefixContent,
  disabled
}) {
  return (
    <span
      className={classnames(className, 'btn', {
        [`btn-${color}`]: color,
        [`btn-size-${size}`]: size,
        [`btn-disabled`]: disabled,
      })}
      onClick={handleClick}
    >
      {prefixContent}
      <button disabled={disabled}>{children}</button>
    </span>
  );
}

export default Button;
