import React, { useState } from "react";
import { Tabs, TabPane } from "../../components/Tab";
import { map } from "lodash";
import { CommentInput, CommentCard } from "../../components/Comment";
import "./style.less";

import Avatar1 from "../../images/avatar-1.png";
import Avatar2 from "../../images/avatar-2.png";
import Avatar3 from "../../images/avatar-3.png";
import image1 from "../../images/pictures_proceed/img_10.jpg";
import image2 from "../../images/pictures_proceed/img_11.jpg";
import image3 from "../../images/pictures_proceed/img_12.jpg";
import image4 from "../../images/pictures_proceed/img_13.jpg";
import image5 from "../../images/pictures_proceed/img_14.jpg";
import image6 from "../../images/pictures_proceed/img_15.jpg";

import { FEEDS_DATA } from "../../constant/data";

function Feed() {
  const avatarIcon = [Avatar1, Avatar2, Avatar3];
  const imagesList = [[image1, image2, image3, image4], [image5, image6], []];

  return (
    <div className="Feed">
      <Tabs defaultActiveIndex={1}>
        <TabPane tab="Following" icon="icon icon-user">
          Following
        </TabPane>
        <TabPane tab="Recommandation" icon="icon icon-check">
          <CommentInput />
          <div className="comment-list">
            {map(FEEDS_DATA, (item, index) => {
              return (
                <CommentCard
                  avatar={avatarIcon[index]}
                  name={item.name}
                  date={item.date}
                  description={item.description}
                  images={imagesList[index]}
                  key={index}
                />
              );
            })}
          </div>
        </TabPane>
        <TabPane tab="Hot" icon="icon icon-fire">
          Hot
        </TabPane>
      </Tabs>
    </div>
  );
}

export default Feed;
