import React, { useState, useEffect, useRef } from 'react';
import BasicModal from './index';
import { FormattedMessage, injectIntl } from 'react-intl';
import Select from 'react-select';
import axios from 'axios';
import { APIS, DEV_PATH } from '../../services/api';
import Button from '../Button';
import { toast } from 'react-toastify';

import uploadIconImg from '../../images/icon-upload.png';
import './UploadWorkModal.less';

function consume(stream, total = 0) {
  while (stream.state === 'readable') {
    var data = stream.read();
    total += data.byteLength;
    console.log(
      'received ' + data.byteLength + ' bytes (' + total + ' bytes in total).'
    );
  }
  if (stream.state === 'waiting') {
    stream.ready.then(() => consume(stream, total));
  }
  return stream.closed;
}

function UploadCourseWorkModal(props) {
  const { uploadWorkState, onModalClose, setUploadWorkState, courseName, courseCover, ref } = props;
  const [uploadFileName, setUploadFileName] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [selectedOption, setSelectedOption] = useState({});
  const [uploadButtonDisable, setUploadButtonDisable] = useState(true);
  const [customWorkName, setCustomWorkName] = useState();
  const [courseNameInputReadOnly, setCourseNameInputReadOnly] = useState(false);

  //let defaultCoverUrl = "https://littleprogrammer-live.oss-cn-shanghai.aliyuncs.com/resources/others/Little%20Programmer-Color-SC-W150ppi.png";
  
  const uploadInputRef = useRef();
  let customizeOption = 
  useEffect(() => {
    if(courseName) {
      setCustomWorkName(courseName);
      setCourseNameInputReadOnly(true);
    }
  }, []);

  const customStyles = {
    overlay: {},
    content: {
      width: '580px',
      height: '560px',
      textAlign: 'left',
      padding: '40px',
    },
  };

  function clearModelState() {
    setUploadProgress(0);
    setUploadFileName(null);
    setSelectedOption({});
    setCustomWorkName();
    console.log('clear state');
  }

  const handleCancel = () => {
    setUploadWorkState(false);
    clearModelState();
  };

  const handleSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleOpenUpload = (e) => {
    uploadInputRef.current?.click();
  };

  const uploadOnChange = (e) => {
    // const file = uploadInputRef.current?.files[0];
    const file = e?.target.files[0];
    const fileReader = new FileReader();
    fileReader.onprogress = function (e) {
      // console.log((e.loaded / e.total).toFixed(2));
      // setUploadProgress((e.loaded / e.total).toFixed(2));
      setUploadFileName(file.name);
    };
    // fileReader.οnabοrt = function () {};
    // fileReader.οnerrοr = function () {};
    // fileReader.οnlοad = function () {};
    fileReader.onloadend = function () {
      setUploadButtonDisable(false);
    };
    fileReader.readAsDataURL(file);
  };

  const handlePublish = (e) => {
    sendSB3ToServer({
      file: uploadInputRef.current?.files[0],
      fileName: uploadFileName,
      workCover: courseCover,
      workStatus: 1,
      customizedWorkName: customWorkName
    });
  };

  const handleSaveAsDraft = (e) => {
    sendSB3ToServer({
      file: uploadInputRef.current?.files[0],
      fileName: uploadFileName,
      workCover: courseCover,
      workStatus: 0,
      customizedWorkName: customWorkName
    });
  };

  const onModalCloseWrapper = () => {
    clearModelState();
    onModalClose && onModalClose();
  };

  function sendSB3ToServer({ file, fileName, workStatus, workCover, customizedWorkName }) {
    const userInfo = JSON.parse(localStorage.getItem('LP_PORTAL_USER'));
    var reader = new FileReader();

    var myHeaders = new Headers();

    myHeaders.append('token', localStorage.getItem('LP_PORTAL_TOKEN'));

    var formdata = new FormData();
    formdata.append('file', file, fileName);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    toast.info('正在上传...');

    const workType = "1";
    fetch(APIS.UPLOAD_WORK_WHOLE_PATH, requestOptions)
      .then((response) => {
        // console.log(response);
        // console.log('upload:', consume(response.body));
        return response.json();
      })
      .then((result) => {
        const workUrl = result?.resp;
        axios
          .post(APIS.SAVE_WORK_INFO, {
            staffId: userInfo.staffId,
            workCover,
            workName: customizedWorkName,
            /**
             * 0: 草稿
             * 1: 已完成
             * 2: 已comment
             */
            workStatus,
            workUrl,
            workType
          })
          .then((response) => {
            if (response?.data.msg === 'success') {
              toast.success('上传成功!');
              clearModelState();
              setTimeout(function () {
                window.location.reload();
              }, 1000);
            }
          });
        // console.log(result);
      })
      .catch((error) => {
        console.log('error', error);
        toast.error('发生了一些问题，上传失败，请重试');
      });
  }

  return (
    <BasicModal
      {...props}
      style={customStyles}
      isOpen={uploadWorkState}
      onModalClose={onModalCloseWrapper}
    >
      <section className="upload-file-section">
        <div className="title">
          <FormattedMessage id="studentPage.uploadWork.title" />
        </div>
        <div>
          <div className="tips">
          <FormattedMessage id="studentPage.uploadWork.workName" />
          </div>
          <div className="tips">
            <input
              size="70"
              className="custom-workname-input"
              type="text"
              value={customWorkName}
              readOnly={courseNameInputReadOnly}
              onChange={(e) => {
                setCustomWorkName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="tips">
          <FormattedMessage id="studentPage.uploadWork.tips.cover" />
        </div>
        <img
            className="cover-image"
            src={courseCover}
            alt="cover image"
          />
        <div className="tips">
          <FormattedMessage id="studentPage.uploadWork.button.uploadFile" />
        </div>
        <input
          ref={uploadInputRef}
          accept=".sb3"
          className="upload-work"
          type="file"
          name="uploadWork"
          id="uploadWork"
          onChange={uploadOnChange}
        />
        {uploadFileName ? (
          <div>
            <div className="file-name">{uploadFileName}</div>
            <div
              className="file-progress"
              style={{
                opacity: uploadProgress > 0 ? 1 : 0,
                width: `${uploadProgress * 100}%`,
              }}
            ></div>
          </div>
        ) : (
          <Button
            className="upload-button"
            color="outline-white"
            handleClick={handleOpenUpload}
            prefixContent={<img className="button-icon" src={uploadIconImg} />}
          >
            <FormattedMessage id="studentPage.uploadWork.button.uploadFile" />
          </Button>
        )}

        <div className="uploadWork-footer">
          <div>
            <Button
              disabled={uploadButtonDisable}
              color="primary-purple"
              handleClick={handlePublish}
              className="buttonMarginRight"
            >
              <FormattedMessage id="studentPage.uploadWork.button.publish" />
            </Button>
            <Button
              disabled={uploadButtonDisable}
              color="outline-white"
              className="outline-white-button"
              handleClick={handleSaveAsDraft}
            >
              <FormattedMessage id="studentPage.uploadWork.button.saveAsDraft" />
            </Button>
          </div>
          <div>
            <Button
              color="outline-white"
              className="outline-white-button"
              handleClick={handleCancel}
            >
              <FormattedMessage id="studentPage.uploadWork.button.cancel" />
            </Button>
          </div>
        </div>
      </section>
    </BasicModal>
  );
}

export default UploadCourseWorkModal;
