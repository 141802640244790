import React, { useState } from 'react';
import axios from 'axios';
import AppLayout from '../AppLayout';
import { useHistory } from 'react-router-dom';
import Logo from '../../images/login-page-logo.png';
import { toast } from 'react-toastify';
import backGroundimage from './img/login-pic.png';
import './style.less';
import Checkbox from '../../components/Checkbox';
import Button from '../../components/Button';
import { FormattedMessage } from 'react-intl';
import encrypt from '../../constant/encrypt';
import { APIS } from '../../services/api';
import { useRecoilState } from 'recoil';
import { userInfo } from '../../atom';

function LoginPage() {
  const [userText, setUserText] = useState('');
  const [pwdText, setPwdText] = useState('');
  const [user, setUser] = useRecoilState(userInfo);
  const [pwdChanged, setPwdChanged] = useState(true);
  const [oldPwdText, setOldPwdText] = useState('');
  const [newPwdText, setNewPwdText] = useState('');
  const [confirmNewPwdText, setConfirmNewPwdText] = useState('');

  let history = useHistory();

  const handleLogin = (e) => {
    if (!userText || !pwdText) {
      return;
    }
    toast.info('登录中...');
    const encrypedUser = encrypt.encrypt(userText);
    const encrypedPwd = encrypt.encrypt(pwdText);
    
    axios
      .post(APIS.LOGIN, {
        password: encrypedPwd,
        staffId: userText,
      })
      .then((data) => {
        const { code, message, token, msg } = data?.data;
        switch (code) {
          case 0:
            toast.success('登录成功！');
            localStorage.setItem('LP_PORTAL_TOKEN', token);
            axios.defaults.headers.common['token'] = token;

            axios
              .get(APIS.QUERY_USER_INFO)
              .then((userInfoDada) => {
                //to change default pwd
                let mobile = userInfoDada.data.resp.mobile;
                let defaultPwd = mobile.substr(-6);
                if(pwdText === defaultPwd) {
                  console.log("logined with default pwd!");
                  setPwdChanged(false);
                } else {
                  history.push('/');
                }

                setUser({ ...user, ...userInfoDada.data?.resp });
                localStorage.setItem(
                  'LP_PORTAL_USER',
                  JSON.stringify({ ...user, ...userInfoDada.data?.resp })
                );
              })
              .catch((err) => {
                console.log('Fetch user info api FAILURE');
              });

            //history.push('/');
            // And then, we fetch control user login status in Header/index.js
            break;
          case 10002:
            toast.error('账号或者密码有误，请检查后再试');
            localStorage.removeItem('LP_PORTAL_TOKEN');
            break;
          default:
            toast.warn('发生了一些错误，请稍候再试');
            break;
        }
      })
      .catch((err) => {
        toast.error('发生了一些错误，请稍候再试');
        console.log(err);
      });
  };

  const handleKeyDown = (e) => {
    if (window.event.keyCode === 13) {
      handleLogin();
    }
  };

  const handleChangePwd = (e) => {
    if (newPwdText != confirmNewPwdText) {
      toast.error('新密码和密码确认不一致！');
      return;
    } 
    var regx =/^(?!([a-zA-Z]+|\d+)$)[a-zA-Z\d]{6,16}$/;
    if(!regx.test(newPwdText)){
      toast.error('密码必由为6到16位的字母和数字组成。');
      return;
    }
    axios
    .post(APIS.CHANGE_PASSWORD, {
      id: user.id,
      originalPassword: encrypt.encrypt(oldPwdText),
      password: encrypt.encrypt(newPwdText),
    })
    .then((data) => {
      const { code, msg } = data?.data;
      if(code === 0) {
        toast.success('修改成功！');
        localStorage.removeItem('LP_PORTAL_TOKEN');
        setPwdChanged(true);
      } else {
        toast.success('出错了！');
        console.log(">>>" + msg)
      }
    })
    .catch((err) => {
      toast.error('发生了一些错误，请稍候再试');
    });
  }

  return (
    <AppLayout login>
      <div className="login-page">
        <div className="login-content">
          <div className="login-bg-image">
            <div className="logo"></div>
            <img src={backGroundimage} alt="" />
          </div>
          <div className="login-model">
            {pwdChanged && <div className="login-input-group">
              <div className="modal-title">
                <FormattedMessage id="loginModal.welcomeBack" />
              </div>
              <div className="login-input-inner login-input-name">
                <div className="input-label input-name-label">
                  <FormattedMessage id="loginModal.userName" />
                </div>
                <input
                  type="text"
                  className="input-login-usrname"
                  type="text"
                  onChange={(e) => {
                    setUserText(e.target.value);
                  }}
                />
              </div>
              <div className="login-input-inner login-input-password">
                <div className="input-label input-password-label">
                  {' '}
                  <FormattedMessage id="loginModal.password" />
                </div>
                <input
                  type="text"
                  className="input-login-password"
                  type="password"
                  onChange={(e) => {
                    setPwdText(e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div className="login-footer">
                {/*<Checkbox*/}
                {/*label="loginModal.keepSignIn"*/}
                {/*className="login-keep-signin"*/}
                {/*id="login-keep-signin"*/}
                {/*/>*/}
                {/*<button*/}
                {/*id="forget-password"*/}
                {/*className="btn-text-link forget-password"*/}
                {/*>*/}
                {/*<FormattedMessage id="loginModal.forgotPassword"/>*/}
                {/*</button>*/}
              </div>
              <Button color="primary" handleClick={handleLogin}>
                <FormattedMessage id="menu.login" />
              </Button>
              <div className="no-account">
                {/*<FormattedMessage id="loginPage.noaccount"/>*/}
                {/*<span className="ask-admin">*/}
                {/*<FormattedMessage id="loginPage.askAdmin"/>*/}
                {/*</span>*/}
              </div>
            </div> 
            }
            {!pwdChanged && <div className="login-input-group">
              <div className="modal-title">
                <FormattedMessage id="loginModal.welcomeBack" />
              </div>
              <div className="login-input-inner login-input-name">
                <div className="input-label input-name-label">
                  <FormattedMessage id="loginModal.userName" />
                </div>
                <input
                  type="text"
                  className="input-login-usrname"
                  type="text"
                  value={user.staffId}
                  readOnly
                />
              </div>
              <div className="login-input-inner login-input-password">
                <div className="input-label input-password-label">
                  {' '}
                  <FormattedMessage id="loginModal.password" />
                </div>
                <input
                  type="text"
                  className="input-login-password"
                  type="password"
                  placeholder="Old password"
                  onChange={(e) => {
                    setOldPwdText(e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                />
                <div className="input-label input-password-label">
                  <span className="password-simple">
                    <FormattedMessage id="password.simple.tip" />
                  </span>
                </div>
              </div>
              <div className="login-input-inner login-input-password">
                <input
                  type="text"
                  className="input-login-password"
                  type="password"
                  placeholder="New password"
                  onChange={(e) => {
                    setNewPwdText(e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div className="login-input-inner login-input-password">
                <input
                  type="text"
                  className="input-login-password"
                  type="password"
                  placeholder="Confirm new password"
                  onChange={(e) => {
                    setConfirmNewPwdText(e.target.value);
                  }}
                  onKeyDown={handleKeyDown}
                />
                <div className="input-label input-password-label">
                  <span className="password-input-tip">
                  <FormattedMessage id="password.input.tip" />
                  </span>
                </div>
              </div>
              <Button color="primary" handleClick={handleChangePwd}>
                <FormattedMessage id="changePwd.submit" />
              </Button>
            </div> 
            }
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default LoginPage;
