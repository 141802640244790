import React, { useState } from 'react';

import AppLayout from '../AppLayout';
import TimeLine from '../../components/TimeLine';
import Banner from '../Home/Banner';
import { Tabs, TabPane } from '../../components/Tab';
import Feed from '../Feed';
import { FormattedMessage } from 'react-intl';
import './style.less';

import Channels from './channels';

function Discover() {
    return (
        <AppLayout>
            <Banner />
            <Tabs defaultActiveIndex={1}>
                <TabPane tab={<FormattedMessage id="discover.event" />}>
                    <TimeLine />
                </TabPane>
                <TabPane tab={<FormattedMessage id="discover.channels" />}>
                    <Channels></Channels>
                </TabPane>
                {/*<TabPane tab={<FormattedMessage id="discover.feed" />}>*/}
                    {/*<Feed />*/}
                {/*</TabPane>*/}
                {/*<TabPane tab={<FormattedMessage id="discover.questions" />}>Questions</TabPane>*/}
            </Tabs>
        </AppLayout>
    );
}

export default Discover;
