import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Autoplay} from 'swiper/core';
import 'swiper/swiper.less';
import Button from '../../components/Button';
import HomeBanner from '../../images/home-banner.png';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Banner from './img/banner.png';
import './newBanner.less';

function NewBanner({ hasDivideLine }) {
  SwiperCore.use([Autoplay]);
  function renderSwiper() {
    return (
      <Swiper centeredSlides={true}
              pagination
              autoplay={{
                "delay": 3000,
                "disableOnInteraction": false
              }}
              loop={true}
              className="mySwiper">
        <SwiperSlide>
          <div className="newBanner">
            <div className="information">
              <div className="title">
                <FormattedMessage id="content.mainTitle" />
              </div>
              <div className="description">
                <FormattedMessage id="content.description" />
              </div>
              <div className="all-courses">
                <Button color="primary" handleClick={changeRoute}>
                  <FormattedMessage id="menu.startNow" />
                </Button>
              </div>
            </div>
            <img src={HomeBanner} />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <img className='secondImg' src={Banner} alt=""/>
        </SwiperSlide>
      </Swiper>
    )
  }
  let history = useHistory();

  const changeRoute = () => {
    history.push('/curriculum');
  };

  return (
    <div className="banner">
      {renderSwiper()}
    </div>

  );
}

export default NewBanner;
