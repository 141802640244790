import React, { useState, useEffect, Fragment } from 'react';
import './style.less';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { APIS, DEV_PATH } from '../../../services/api';
import { toast } from 'react-toastify';
import LearningPathItem from './learningPathItem';
import LearningPathItemDetail from './learningPathItemDetail';

function LearningPath() {
    const history = useHistory();

    const [learningPathList, setLearningPathList] = useState([]);
    const [selectedLearningPathItem, setSelectedLearningPathItem] = useState({});
    const [selectedLearningPathItemIndex, setSelectedLearningPathItemIndex] = useState(undefined);
    const [selectedLearningPathDetailItems, setSelectedLearningPathDetailItems] = useState({});
    const [selectedLearningPathId, setSelectedLearningPathId] = useState('');

    const userInfo = JSON.parse(localStorage.getItem('LP_PORTAL_USER'));

    useEffect(() => {
        onInit();
    }, []);

    const onInit = () => {
        requestLearningPathList();
    };
    const requestLearningPathList = () => {
        const URL = `${DEV_PATH}${APIS.LEARNING_PATH_LIST}`;
        axios({
            method: 'GET',
            url: URL,
            params: {
                status: 0,
            },
        })
            .then((res) => {
                setLearningPathList(res.data.resp);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const requestCourseList = (id) => {
        axios({
            method: 'GET',
            url: APIS.QUERY_COURSE_LIST,
            params: {
                pathId: '1394908367510048769',
            },
        })
            .then((res) => {
                setSelectedLearningPathDetailItems(res.data.resp);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const learningPathItemOnClick = (item, itemIndex) => {
        setSelectedLearningPathItem(item);
        setSelectedLearningPathItemIndex(itemIndex);
        requestCourseList(item.id);
        setSelectedLearningPathId(item.id);
    };
    const learningPathDetailItemOnClick = (detailItem) => {
        history.push({ pathname: '/curriculum-detail', state: { id: detailItem.id, coursePackage: detailItem.coursePackage } });
        setTimeout(function () {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 500);
    };

    const collectLearnPath = (pathId, status) => {
      axios({
        method: 'GET',
        url: APIS.COLLECT_LEARN_PATH,
        params: {
          pathId: pathId,
          status: status,
          staffId: userInfo.staffId
        },
      })
        .then((res) => {
          console.log(res);
          toast.success('添加成功');
          requestLearningPathList();// 重新获取收藏状态
        })
        .catch((error) => {
          console.log(error);
        });
    };

  return (
        <div className="learningPathContainer">
            {learningPathList &&
                learningPathList.length &&
                learningPathList.length > 0 &&
                learningPathList.map((item, index) => {
                    return (
                        <Fragment key={item.id}>
                            <LearningPathItem item={item} selectedLearningPathItem={selectedLearningPathItem} itemIndex={index} learningPathItemOnClick={learningPathItemOnClick}/>
                            {index % 2 === 1 && (
                                <LearningPathItemDetail
                                    learningPathItemDetailId={Math.floor(index / 2)}
                                    selectedLearningPathItemIndex={selectedLearningPathItemIndex}
                                    selectedLearningPathDetailItems={selectedLearningPathDetailItems}
                                    learningPathDetailItemOnClick={learningPathDetailItemOnClick}
                                    selectedLearningPathId={selectedLearningPathId}
                                    collectLearnPath={collectLearnPath}
                                />
                            )}
                        </Fragment>
                    );
                })}
            {learningPathList && learningPathList.length && learningPathList.length > 0 && learningPathList.length % 2 === 1 && (
                <LearningPathItemDetail
                    learningPathItemDetailId={Math.floor((learningPathList.length - 1) / 2)}
                    selectedLearningPathItemIndex={selectedLearningPathItemIndex}
                    selectedLearningPathDetailItems={selectedLearningPathDetailItems}
                    learningPathDetailItemOnClick={learningPathDetailItemOnClick}
                    selectedLearningPathId={selectedLearningPathId}
                    collectLearnPath={collectLearnPath}
                />
            )}
        </div>
    );
}

export default LearningPath;
