import React from 'react';
import { useClickOutside } from 'hook-click-outside';
import './style.less';

import { useHistory } from 'react-router-dom';

function UserProfileMenu(props) {
    const history = useHistory();
    let userProfileMenuOpen = props.userProfileMenuOpen;

    const node = useClickOutside(() => {
        props.closeMenu();
    });

    const handleLogOut = () => {
        localStorage.removeItem('LP_PORTAL_TOKEN');
        history.push('login');
    };

    return (
        userProfileMenuOpen && (
            <div ref={node} className="userProfileMenu">
                <ul className="menuUl">
                    <li
                        className="menuLi"
                        onClick={(e) => {
                            props.closeMenu();
                            history.push('/me');
                        }}
                    >
                        我的作品
                    </li>
                    <li
                        className="menuLi"
                        onClick={(e) => {
                            props.closeMenu();
                            history.push('/me');
                        }}
                    >
                        个人资料
                    </li>
                    <li
                        className="menuLi"
                        onClick={() => {
                            props.closeMenu();
                            handleLogOut();
                        }}
                    >
                        登出
                    </li>
                </ul>
            </div>
        )
    );
}

export default UserProfileMenu;
