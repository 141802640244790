import React from 'react';
import './index.less';
import classnames from 'classnames';
import {FormattedMessage, injectIntl} from 'react-intl';

function Checkbox({ id, className, label }) {
  const wrapperClassName = classnames('checkbox-container', className);
  return (
    <label className={wrapperClassName}>
      <input id={id} type="checkbox" />
      <span className="checkbox-checkmark"></span>
      {/* {label} */}
      <FormattedMessage id={label} />
    </label>
  );
}

export default Checkbox;
