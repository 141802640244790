import React from 'react';
import { useClickOutside } from 'hook-click-outside';
import './style.less';

function languageSelectMenu(props) {
    let languageSelectOpen = props.languageSelectOpen;
    const node = useClickOutside(() => {
        props.closeMenu();
    });
    return (
        languageSelectOpen && (
            <div ref={node} className="languageSelectMenuContainer">
                <ul className="menuUl">
                    <li
                        onClick={(e) => {
                            props.closeMenu();
                        }}
                    >
                        简体中文
                    </li>
                    <li
                        onClick={(e) => {
                            props.closeMenu();
                        }}
                    >
                        English
                    </li>
                </ul>
            </div>
        )
    );
}

export default languageSelectMenu;
