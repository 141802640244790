import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import moment from 'moment';
import AppLayout from '../AppLayout';
import Section from '../../components/Section';
import { FullCard } from '../../components/Card';
import Card from './Card';
import NewBanner from './NewBanner';
import Introduction from './Introduction';
import Recommend from './Recommend';
import { useHistory } from 'react-router-dom';
import "swiper/swiper.less";
import "swiper/components/pagination/pagination.less"
import "swiper/components/navigation/navigation.less"
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { APIS, DEV_PATH } from '../../services/api';
import "./index.less"
import Img1 from '../../images/pictures_proceed/background1.png';
import Img2 from '../../images/pictures_proceed/background2.png';
import Img3 from '../../images/pictures_proceed/background3.png';
// import Swiper core and required modules
import SwiperCore, {
  Pagination,Navigation
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Pagination,Navigation]);
function Home() {
  let history = useHistory();
  const [learningPathList, setLearningPathList] = useState([]);
  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  useEffect(() => {
      // when no path id it will get all course
    requestCourseList('1394908367510048769');
  }, []);

  const requestCourseList = (id) => {
    // const URL = `${DEV_PATH}${APIS.QUERY_COURSE_LIST}`;
    axios({
      method: 'GET',
      url: APIS.QUERY_COURSE_LIST,
      // params: {
      //   pathId: id,
      // },
    })
      .then((res) => {
        const data = res.data.resp;
        // console.log(data);
        data.length ? setLearningPathList(data) : setLearningPathList([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeRoute = (id, coursePackage, ifUpload, courseName, cover) => {
    history.push({pathname:'/curriculum-detail', state:{id, coursePackage, ifUpload, courseName, cover}});
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      // scrollToAnchor('curriculum-detail')
    }, 500);
  };

  const redirectToCurriculum = () => {
    history.push({pathname:'/curriculum'});
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      // scrollToAnchor('curriculum-detail')
    }, 500);
  }

  const getLearningPath = () => {};
  return (
    <AppLayout>
      <NewBanner hasDivideLine={true} />
      <Introduction />
      <Section
        title={<FormattedMessage id="introduction.subTitle" />}
        isGrey={true}
        description={<FormattedMessage id="introduction.description" />}
      >
        <FullCard
          title={<FormattedMessage id="introduction.block1" />}
          image={Img1}
        />
        <FullCard
          title={<FormattedMessage id="introduction.block2" />}
          image={Img2}
        />
        <FullCard
          title={<FormattedMessage id="introduction.block3" />}
          image={Img3}
        />
      </Section>


        <div className="swiper-container">
          <div className="title">
            <div className="curriculumTitle">
              <FormattedMessage id="introduction.curriculum" />
            </div>
            <div className="more" onClick={redirectToCurriculum}>
              <FormattedMessage id="introduction.more" />
            </div>
          </div>
          <div className='pre-swiper'>
            <Swiper slidesPerView={3} slidesPerGroup={3} loopFillGroupWithBlank={true} pagination={{
              "clickable": true
            }} navigation={true} className="mySwiper">
              {learningPathList.map((items, index) => {
                return (
                  <SwiperSlide>
                    <Card
                      key={index}
                      title={items.courseName}
                      description={items.courseDescribe}
                      date={moment(Number(items.createTime)).format('YYYY/MM/DD')}
                      image={items.cover}
                      handleClick={() => changeRoute(items.id, items.coursePackage, items.ifUpload, items.courseName, items.cover)}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      <Recommend />
      {/*<Section title={<FormattedMessage id="introduction.block1" />}>*/}
        {/*{learningPathList.map((items, index) => {*/}
          {/*return (*/}
            {/*<Card*/}
              {/*key={index}*/}
              {/*title={items.courseName}*/}
              {/*description={items.courseDescribe}*/}
              {/*date={new Date(Number(items.createTime)).toLocaleDateString()}*/}
              {/*image={items.cover}*/}
              {/*handleClick={changeRoute}*/}
            {/*/>*/}
          {/*);*/}
        {/*})}*/}
        {/* <Card
          title="Introduction to programming"
          description="Majority of peole will work in jobs that don’t exist today."
          date="02 May"
          image={Img4}
        />
        <Card
          title="Building animation with a story"
          description="Majority of peole will work in jobs that don’t exist today."
          date="02 May"
          image={Img5}
        />
        <Card
          title="Create your game world"
          description="Majority of peole will work in jobs that don’t exist today."
          date="02 May"
          image={Img6}
        /> */}
      {/*</Section>*/}
    </AppLayout>
  );
}

export default Home;
