import React, { useState, useEffect } from "react";
import classnames from "classnames";

import "./style.less";

function Tabs({ children, defaultActiveIndex }) {
  console.log(defaultActiveIndex);
  const [currentTab, setCurrentTab] = useState(defaultActiveIndex === 0 ? 0 : 1);

  useEffect(() => {
    defaultActiveIndex !== undefined && setCurrentTab(defaultActiveIndex);
  }, []);

  const getTabDetail = () => {
    const tabList = [];
    let tabContent;

    React.Children.map(children, (item) => {
      tabList.push({ tabName: item.props.tab, icon: item.props.icon });
    });

    tabContent = children[currentTab];

    return { tabList, tabContent };
  };

  const handleTabClick = (tab, tabList) => (e) => {
    const index = tabList.findIndex((item) => item === tab);

    if (index !== currentTab) {
      setCurrentTab(index);
    }
  };

  const renderTabList = (tabList) =>
    tabList.map((tab, index) => (
      <div
        key={index}
        onClick={handleTabClick(tab, tabList)}
        className={classnames("tab-item", { current: currentTab === index })}>
        <i className={tab.icon}></i>
        {tab.tabName}
      </div>
    ));

  const { tabList, tabContent } = getTabDetail();

  return (
    <div className="tabs">
      <div className="container">
        <div className="tab-list">{renderTabList(tabList)}</div>
        <div className="tab-content">{tabContent}</div>
      </div>
    </div>
  );
}

export default Tabs;
