import React, { useState, useEffect } from 'react';
import BasicModal from '../Modal/index';
import Button from '../Button';
import { FormattedMessage } from 'react-intl';

import PointSelected from '../../images/point_selected.png';
import PointUnSelected from '../../images/point_unselected.png';

import './viewComments.less';

function ViewComments(props) {
    return (
        <BasicModal {...props} class="view-comments-modal">
            <div className="modal-title">{props.title}</div>
            <div className="modal-label">{props.overallscoreLabel}</div>
            <div className="modal-score">
                {props.workScore && new Array(5).fill().map((item, index) => {
                    if (index < props.workScore) {
                        return (
                            <img key={index} src={PointSelected}></img>
                        );
                    } else {
                        return (
                            <img key={index} src={PointUnSelected}></img>
                        );
                    }
                })}
            </div>
            <div className="modal-label">{props.teachercommentsLabel}</div>
            <div className="modal-content">{props.comments}</div>
            <div className="basic-modal-footer">
                <Button color="default" size="small" handleClick={props.handleClose}>
                    <FormattedMessage id="viewComments.close" />
                </Button>
            </div>  

        </BasicModal>
    );
}

export default ViewComments;
