import React, { Fragment } from 'react';

import Button from '../../components/Button';
import HomeBanner from '../../images/curriculum_img.png';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { langState } from '../../atom';
import { useRecoilState} from 'recoil';

import './banner.less';

function BannerCurriculum({ hasDivideLine }) {
  const [lang, setLang] = useRecoilState(langState);

  let history = useHistory();

  const changeRoute = () => {
    const link = lang !== '' ? `/scratch?locale=${lang}` : '/scratch';
    history.push(link);
  };

  return (
    <Fragment>
      <div className="banner banner-currilum ">
        <img src={HomeBanner} />
        <div className="information">
          <div className="title">
            <FormattedMessage id="content.curriculumBannerTitle" />
          </div>
          <div className="description">
            <FormattedMessage id="content.curriculumBannerContent" />
          </div>
          <div className="all-courses">
          
            <Button color="primary" handleClick={changeRoute}>
              <FormattedMessage id="content.curriculumBannerButton" />
            </Button>
          </div>
        </div>
      </div>
      {hasDivideLine && <div className="divide-line"></div>}
    </Fragment>
  );
}

export default BannerCurriculum;
