import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { APIS } from '../../../services/api';
import { WORK_STATUS } from '../../../constant/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import ViewComments from '../../../components/Modal/viewComments';
import NotificationModal from '../../../components/Modal/NotificationModal';
import Button from '../../../components/Button';
import NoWorksSection from '../NoWorksSection';
import { compoutMenuPosition } from '../../../constant/utiles';
import { toast } from 'react-toastify';
import BG from './img/bg.png';
import menuSrc from './img/menu.png';
import checkIcon from './img/check_icon.png';

import './style.less';

function StudentPage(props) {
    const history = useHistory();
    const { intl, user } = props;
    const [worksList, setWorksList] = useState([]);
    const [filter, setFilter] = useState('all');
    const [menuModalOpen, setMenuModalOpen] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(null);
    const [menuStyle, setMenuStyle] = useState({});
    const [operationModalParam, setOperationModalParam] = useState({});
    const [workStatus, setWorkStatus] = useState(WORK_STATUS.DRAFT);
    const [operationViewCommentsModalParam, setOperationViewCommentsModalParam] = useState({});
    const [workComments, setWorkComments] = useState({});
    

    const getWorksList = (fiterStatus) => {
        let params = { staffId: user.staffId };
        switch (fiterStatus) {
            case 'published':
                params.workStatus = WORK_STATUS.PUBLISHED;
                break;
            case 'draft':
                params.workStatus = WORK_STATUS.DRAFT;
                break;
            default:
                break;
        }

        axios.get(APIS.GET_WORKS_BY_STAFFID, { params }).then((data) => {
            const response = data?.data.resp;
            setWorksList(response);
        });
    };

    const handleFilterButton = (fiterStatus) => {
        getWorksList(fiterStatus);
        setFilter(fiterStatus);
    };

    useEffect(() => {
        if (worksList.length === 0) {
            axios
                .get(APIS.GET_WORKS_BY_STAFFID, {
                    params: {
                        staffId: user?.staffId,
                    },
                })
                .then((data) => {
                    const response = data?.data.resp;
                    setWorksList(response);
                });
        }
    }, []);

    // 点击 menu 触发icon以外任何地方关闭 menu
    const removeMenu = (e) => {
        if (e?.target?.id !== 'workMenuIcon') {
            setMenuModalOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', removeMenu);
        return () => {
            document.removeEventListener('click', removeMenu);
        };
    }, []);

    function renderStar(number) {
        let array = [];
        for (let i = 0; i < number; i++) {
            array.push(<div className="star" />);
        }
        return array;
    }

    const handleMenu = (id, workStatus) => (e) => {
        const { left, top } = compoutMenuPosition(e);

        setMenuStyle({
            top: `${top + 10}px`,
            left: `${left - 180}px`,
        });
        setMenuModalOpen(true);
        setCurrentItemId(id);
        setWorkStatus(workStatus);
    };
    
    const handlePublish = (e) => {
        setOperationModalParam({
            isOpen: true,
            handleContinue: handleConrirmModalContinue,
            handleCancel: handleConrirmModalCancel,
            title: intl.formatMessage({
                id: 'studentPage.workPublishConfirmTitle',
            }),
            content: intl.formatMessage({
                id: 'studentPage.workPublishConfirmContent',
            }),
        });
    };

    const handleConrirmModalContinue = (e) => {
        setOperationModalParam({
            isOpen: false,
        });

        axios.post(APIS.UPDATE_WORK_INFO, { id: currentItemId, workStatus: 1 }).then((data) => {
            if (data?.data.msg === 'success') {
                toast.success('发布成功!');
                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            } else {
                toast.info('出现了一些问题，请重试');
            }
        });
    };

    const handleConrirmModalCancel = (e) => {
        setOperationModalParam({
            isOpen: false,
        });
    };

    const handleDeleteModalContinue = (e) => {
        const newList = worksList.filter((item) => currentItemId !== item.id);

        setOperationModalParam({
            isOpen: false,
        });

        axios.get(APIS.DELETE_WORK_INFO, { params: { id: currentItemId } }).then((data) => {
            if (data?.data.msg === 'success') {
                setWorksList(newList);
                toast.success('删除成功!');
                setTimeout(function () {
                    window.location.reload();
                }, 1000);
            } else {
                toast.info('出现了一些问题，请重试');
            }
        });
    };

    const handleDelete = (e) => {
        setOperationModalParam({
            isOpen: true,
            handleContinue: handleDeleteModalContinue,
            handleCancel: handleConrirmModalCancel,
            title: intl.formatMessage({
                id: 'studentPage.workDeleteConfirmTitle',
            }),
        });
    };

    const handleItemClick = (item) => {
        let itemCopy = JSON.parse(JSON.stringify(item));
        if (itemCopy.workStatus && (itemCopy.workStatus === WORK_STATUS.PUBLISHED || itemCopy.workStatus === REVIEWED)) {
            itemCopy.isPlayOnly = true;
        }
        let query = {
            pathname: '/scratch',
            query: { worksItem: itemCopy },
        };
        history.push(query);
    };

    const handleCloseModal = (e) => {
        setOperationViewCommentsModalParam({
            isOpen: false,
        });
    }

    const handleViewComments = (e) => {
        axios({
            method: 'GET',
            url: APIS.GET_USER_WORK_BY_ID_LIST,
            headers: {
                'token': localStorage.getItem('LP_PORTAL_TOKEN')
            },
            params: {
              id: currentItemId
            },
          })
        .then((res) => {
            const response = res.data.resp;
            let optParams = {
                isOpen: true,
                handleClose: handleCloseModal,
                title: intl.formatMessage({
                    id: 'viewComments.title',
                }),
                overallscoreLabel: intl.formatMessage({
                    id: 'viewComments.label.overallscore',
                }),
                workScore: response.workScore,
                teachercommentsLabel: intl.formatMessage({
                    id: 'viewComments.label.teachercomments',
                }),
                comments: response.comments,
            };
            
            setOperationViewCommentsModalParam(optParams);
        })
        .catch((error) => {
            console.log(error);
        });
        
    };

    const workTypeToName = (workType) => {
        if(workType == 1) {
            return <FormattedMessage id="studentPage.uploadWork.workTypeOption.Assignment" />
        } else if(workType == 2) {
            return <FormattedMessage id="studentPage.uploadWork.workTypeOption.Creation" />
        } else if(workType == 3) {
            return <FormattedMessage id="studentPage.uploadWork.workTypeOption.Competition" />
        } else {
            return <FormattedMessage id="studentPage.uploadWork.workTypeOption.Undefined" />
        }

    }

    return (
        <div className="student-page">
            <div className="btn-container">
                <Button color={filter === 'all' ? 'primary-purple' : 'outline-white'} handleClick={() => handleFilterButton('all')}>
                    {filter === 'all' && <img className="checkIconImg" src={checkIcon} />}
                    <FormattedMessage id="studentPage.menu.all" />
                </Button>
                <Button color={filter === 'published' ? 'primary-purple' : 'outline-white'} handleClick={() => handleFilterButton('published')}>
                    {filter === 'published' && <img className="checkIconImg" src={checkIcon} />}
                    <FormattedMessage id="studentPage.menu.published" />
                </Button>
                <Button color={filter === 'draft' ? 'primary-purple' : 'outline-white'} handleClick={() => handleFilterButton('draft')}>
                    {filter === 'draft' && <img className="checkIconImg" src={checkIcon} />}
                    <FormattedMessage id="studentPage.menu.draft" />
                </Button>
            </div>
            <div className="work-container">
                {worksList.length === 0 ? <NoWorksSection /> : null}
                {worksList.length > 0
                    ? worksList?.map((item, index) => {
                          return (
                              <div className="works-box" key={item.id}>
                                  <div className="menu" onClick={handleMenu(item.id, item.workStatus)}>
                                      <img id="workMenuIcon" className="menu-img" src={menuSrc} alt="menu" />
                                  </div>
                                  <div
                                      href={item.workUrl}
                                      download
                                      className="work-download"
                                      onClick={(e) => {
                                          handleItemClick(item);
                                      }}
                                  >
                                      <div className="star-range">{item?.remark ? renderStar(item?.remark) : null}</div>
                                      <img className="work-cover" src={item.workCover} alt="" />
                                      <div className="title">
                                          {item.workStatus === WORK_STATUS.DRAFT ? <span>[草稿]</span> : null}
                                          {item.workName}
                                      </div>
                                      <div className="footer">
                                          <div className="date">{item.modifyTime?.slice(0, 10)}</div>
                                          <div className="work-type">#{workTypeToName(item.workType)}</div>
                                      </div>
                                      
                                  </div>
                              </div>
                          );
                      })
                    : null}

                {menuModalOpen && workStatus === WORK_STATUS.DRAFT && (
                    <div className="menu-body" style={menuStyle}>
                        <div className="menu-item" onClick={handlePublish}>
                            <FormattedMessage id="studentPage.workMenuPublish" />
                        </div>
                        <div className="menu-item" onClick={handleDelete}>
                            <FormattedMessage id="studentPage.workMenuDelete" />
                        </div>
                    </div>
                )}
                {menuModalOpen && workStatus !== WORK_STATUS.DRAFT && (
                    <div className="menu-body" style={menuStyle}>
                        <div className="menu-item" onClick={handleViewComments}>
                            <FormattedMessage id="studentPage.workMenuViewComments" />
                        </div>
                    </div>
                )}

                <NotificationModal
                    isOpen={operationModalParam?.isOpen}
                    handleContinue={operationModalParam?.handleContinue}
                    handleCancel={operationModalParam?.handleCancel}
                    title={operationModalParam?.title}
                    content={operationModalParam?.content}
                    onModalClose={operationModalParam?.handleCancel}
                />
                <ViewComments
                    isOpen={operationViewCommentsModalParam?.isOpen}
                    handleClose={operationViewCommentsModalParam?.handleClose}
                    title={operationViewCommentsModalParam?.title}
                    overallscoreLabel={operationViewCommentsModalParam?.overallscoreLabel}
                    workScore={operationViewCommentsModalParam?.workScore}
                    teachercommentsLabel={operationViewCommentsModalParam?.teachercommentsLabel}
                    comments={operationViewCommentsModalParam?.comments}
                    onModalClose={operationViewCommentsModalParam?.handleClose}
                />
            </div>
        </div>
    );
}

export default injectIntl(StudentPage);
