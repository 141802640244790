import Img1 from '../images/pictures_proceed/img_1.jpg';
import Img2 from '../images/pictures_proceed/img_2.jpg';
import Img3 from '../images/pictures_proceed/img_3.jpg';

import { APIS } from '../services/api';

const genRandom = (start, end) =>
  Math.floor(Math.random() * (start - end) + end);

const compoutMenuPosition = (event) => {
  const e = event || window.event;

  const scrollX =
    document.documentElement.scrollLeft || document.body.scrollLeft;

  const scrollY = document.documentElement.scrollTop || document.body.scrollTop;

  const x = e.pageX || e.clientX + scrollX;

  const y = e.pageY || e.clientY + scrollY;

  return { top: y, left: x };
};

// mock data
const timelineEvent = {
  2020: {
    9: [
      {
        event: {
          title: 'Create your game world',
          description:
            "Choose what you'd like to learn from our extensive subscription library.",
        },
        picture: Img1,
      },
      {
        event: {
          title: 'Create your game world2',
          description:
            "Choose what you'd like to learn from our extensive subscription library2.",
        },
        picture: Img2,
      },
      {
        event: {
          title: 'Create your game world3',
          description:
            "Choose what you'd like to learn from our extensive subscription library.",
        },
        picture: Img3,
      },
      {
        event: {
          title: 'Create your game world4',
          description:
            "Choose what you'd like to learn from our extensive subscription library2.",
        },
        picture: Img1,
      },
    ],

    8: [
      {
        event: {
          title: 'Create your game world',
          description:
            "Choose what you'd like to learn from our extensive subscription library.",
        },
        picture: Img2,
      },
      {
        event: {
          title: 'Create your game world2',
          description:
            "Choose what you'd like to learn from our extensive subscription library2.",
        },
        picture: Img3,
      },
      {
        event: {
          title: 'Create your game world3',
          description:
            "Choose what you'd like to learn from our extensive subscription library.",
        },
        picture: Img1,
      },
      {
        event: {
          title: 'Create your game world4',
          description:
            "Choose what you'd like to learn from our extensive subscription library2.",
        },
        picture: Img2,
      },
      {
        event: {
          title: 'Create your game world5',
          description:
            "Choose what you'd like to learn from our extensive subscription library.",
        },
        picture: Img3,
      },
      {
        event: {
          title: 'Create your game world6',
          description:
            "Choose what you'd like to learn from our extensive subscription library2.",
        },
        picture: Img1,
      },
    ],
  },
  2019: {
    2: [
      {
        event: {
          title: 'Create your game world',
          description:
            "Choose what you'd like to learn from our extensive subscription library.",
        },
        picture: Img1,
      },
      {
        event: {
          title: 'Create your game world2',
          description:
            "Choose what you'd like to learn from our extensive subscription library2.",
        },
        picture: Img2,
      },
      {
        event: {
          title: 'Create your game world3',
          description:
            "Choose what you'd like to learn from our extensive subscription library.",
        },
        picture: Img3,
      },
      {
        event: {
          title: 'Create your game world4',
          description:
            "Choose what you'd like to learn from our extensive subscription library2.",
        },
        picture: Img1,
      },
    ],

    1: [
      {
        event: {
          title: 'Create your game world',
          description:
            "Choose what you'd like to learn from our extensive subscription library.",
        },
        picture: Img2,
      },
      {
        event: {
          title: 'Create your game world2',
          description:
            "Choose what you'd like to learn from our extensive subscription library2.",
        },
        picture: Img3,
      },
      {
        event: {
          title: 'Create your game world3',
          description:
            "Choose what you'd like to learn from our extensive subscription library.",
        },
        picture: Img1,
      },
      {
        event: {
          title: 'Create your game world4',
          description:
            "Choose what you'd like to learn from our extensive subscription library2.",
        },
        picture: Img2,
      },
      {
        event: {
          title: 'Create your game world5',
          description:
            "Choose what you'd like to learn from our extensive subscription library.",
        },
        picture: Img3,
      },
      {
        event: {
          title: 'Create your game world6',
          description:
            "Choose what you'd like to learn from our extensive subscription library2.",
        },
        picture: Img1,
      },
    ],
  },
};

export { timelineEvent, genRandom, compoutMenuPosition };
