import React, {Fragment} from 'react';
import {IntlProvider} from 'react-intl';
import {
  useRecoilState
} from 'recoil';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Nav from '../../components/Nav';
import LiveVideoModal from '../../components/Modal/LiveVideoModal';
import messagesInEN from '../../lang/en.json';
import messagesInZHCN from '../../lang/zh-cn.json';

const langMap = {
  'en': messagesInEN,
  'zh-cn': messagesInZHCN
};

import {langState} from '../../atom';

function AppLayout({children, login}) {
  const [lang, setLang] = useRecoilState(langState);

  function renderContext() {
    if (login) {
      return (
        <Fragment>
          {children}
        </Fragment>
      )
    }
    return (
      <Fragment>
        <Header/>
        <Nav/>
        <LiveVideoModal/>
        {children}
        <Footer/>
      </Fragment>
    )

  }

  return (
    <IntlProvider messages={langMap[lang]} locale={lang} defaultLocale="zh-cn">
      {renderContext()}
    </IntlProvider>
  );
}

export default AppLayout;
