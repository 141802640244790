import React, { Fragment } from "react";

import Section from "../../components/Section";
import RecommendImg from "../../images/recommend.png";
import QuotationBegin from "../../images/quotation-begin.png";
import QuotationEnd from "../../images/quotation-end.png";
import { FormattedMessage } from 'react-intl';

import "./recommend.less";

function Recommend() {
  return (
    <Section
      className="recommend-section"
      isGrey={true}>
      <img src={RecommendImg} />
      <div className="information">
        <div className="quotation">
          <img src={QuotationBegin} />
          <img src={QuotationEnd} />
        </div>
        <div className="description">
          <div>
            <FormattedMessage id="recommend.description" />
          </div>
          <div>
            <FormattedMessage id="recommend.parent" />
          </div>
        </div>
      </div>
    </Section>
  );
}

export default Recommend;
