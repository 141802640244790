import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import './style.less';
import Button from '../../../components/Button';
import CommentModal from '../../../components/Modal/commentModal.js';

import axios from 'axios';
import { APIS } from '../../../services/api';
import { WORK_STATUS } from '../../../constant/index';
import checkIcon from './img/check_icon.png';
import iconCheckedGreen from '../../../images/icon_checked_green.png';
import icon_review_purple from '../../../images/icon_review_purple.png';

function TeacherPage(props) {
    const history = useHistory();
    const [worksList, setWorksList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [id, setId] = useState('');
    const [comment, setCommnet] = useState('');
    const [workScore, setWorkScore] = useState(undefined);
    const [filter, setFilter] = useState('all');

    const getWorksList = (fiterStatus) => {
        let params = {};
        switch (fiterStatus) {
            case 'pending':
                params.workStatus = WORK_STATUS.PUBLISHED;
                break;
            case 'reviewed':
                params.workStatus = WORK_STATUS.REVIEWED;
                break;
            default:
                break;
        }
        axios.get(APIS.GET_WORKS_BY_STAFFID, { params }).then((data) => {
            const response = data?.data.resp;
            setWorksList(response);
        });
    };

    const updateComment = (id, updatedComment, workScore) => {
        axios.post(APIS.UPDATE_WORK_INFO, { comments: updatedComment, workScore, id, workStatus: 2 }).then((data) => {
            if (data.data.msg === 'success') {
                getWorksList(filter);
            }
        });
    };

    useEffect(() => {
        getWorksList(filter);
    }, []);

    const handleFilterButton = (fiterStatus) => {
        getWorksList(fiterStatus);
        setFilter(fiterStatus);
    };

    const handleAddComment = (id, commnet, workScore) => {
        setId(id);
        setCommnet(commnet);
        setWorkScore(workScore);
        setModalOpen(true);
    };

    const handleConfirmComment = (modalComment, workScore) => {
        updateComment(id, modalComment, workScore);
        setModalOpen(false);
    };

    const handleCancelComment = () => {
        setModalOpen(false);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleItemClick = (item) => {
        let itemCopy = JSON.parse(JSON.stringify(item));
        itemCopy.isPlayOnly = true;
        let query = {
            pathname: '/scratch',
            query: { worksItem: itemCopy },
        };
        history.push(query);
    };

    function commentStatus(workStatus, commnet, workScore, id) {
        // workStatus === 2 已经评论
        if (workStatus === 2) {
            return (
                <div
                    className="add-comment"
                    onClick={(e) => {
                        e.stopPropagation();
                        handleAddComment(id, commnet, workScore);
                    }}
                >
                    <img align="absmiddle" src={iconCheckedGreen} />
                    <div>
                        <FormattedMessage id="teacherPage.addedAcomment" />
                    </div>
                </div>
            );
        }
        return (
            <div
                className="add-comment"
                onClick={(e) => {
                    e.stopPropagation();
                    handleAddComment(id, commnet, workScore);
                }}
            >
                <img align="absmiddle" src={icon_review_purple} />
                <div>
                    <FormattedMessage id="teacherPage.addComment" />
                </div>
            </div>
        );
    }

    return (
        <div className="teacher-page">
            <div className="function-container">
                <div className="btn-container">
                    <Button color={filter === 'all' ? 'primary-purple' : 'outline-white'} handleClick={() => handleFilterButton('all')}>
                        {filter === 'all' && <img className="checkIconImg" src={checkIcon} />}
                        <FormattedMessage id="teacherPage.All" />
                    </Button>
                    <Button color={filter === 'pending' ? 'primary-purple' : 'outline-white'} handleClick={() => handleFilterButton('pending')}>
                        {filter === 'pending' && <img className="checkIconImg" src={checkIcon} />}
                        <FormattedMessage id="teacherPage.pendingReview" />
                    </Button>
                    <Button color={filter === 'reviewed' ? 'primary-purple' : 'outline-white'} handleClick={() => handleFilterButton('reviewed')}>
                        {filter === 'reviewed' && <img className="checkIconImg" src={checkIcon} />}
                        <FormattedMessage id="teacherPage.reviewed" />
                    </Button>
                </div>
                <div className="search-bar">
                    <div className="icon" />
                    <input />
                </div>
            </div>
            <div className="comment-container">
                {worksList.map((items, index) => {
                    return (
                        <div
                            className="comment-box"
                            key={index}
                            onClick={() => {
                                handleItemClick(items);
                            }}
                        >
                            <div className="title">
                                <div className="works-name">{items.workName}</div>
                                {commentStatus(items.workStatus, items.comments, items.workScore, items.id)}
                            </div>
                            <div className="info">
                                {items.staffId} {items.schoolName}
                            </div>
                        </div>
                    );
                })}
            </div>
            <CommentModal isOpen={modalOpen} onModalClose={handleModalClose} confirmComment={handleConfirmComment} cancelComment={handleCancelComment} comment={comment} workScore={workScore} />
        </div>
    );
}

export default TeacherPage;
