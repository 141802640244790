import React, { useState, useEffect } from 'react';

import Button from '../Button';
import './style.less';
import Logo from '../../images/logo.png';
import Logo_Small from '../../images/logo_small.png';
import LoginModal from '../Modal/LoginModal';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { userInfo, langState } from '../../atom';
import { useHistory } from 'react-router-dom';

import LanguageSelectMenu from './languageSelectMenu';
import UserProfileMenu from './userProfileMenu';

const localeMap = {
  en: 'English',
  'zh-cn': '中文',
};

function Header(props) {
  const [modalOpen, setModalOpen] = useState(false);
  const [languageSelectOpen, setLanguageSelectOpen] = useState(false);
  const [userProfileMenuOpen, setUserProfileMenuOpen] = useState(false);
  const [lang, setLang] = useRecoilState(langState);
  const [user, setUser] = useRecoilState(userInfo);
  const [selectedOption, setSelectedOption] = useState('zh-cn');
  const urlParams = new URLSearchParams(window.location.search);
  const [locale, setLocale] = useState(urlParams.get('locale'));

  let history = useHistory();

  const handleClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleLogOut = () => {
    localStorage.removeItem('LP_PORTAL_TOKEN');
    // setUser({ ...user, loginStatus: false });
    history.push('login');
  };

  useEffect(() => {
    const token = localStorage.getItem('LP_PORTAL_TOKEN');
    if (token) {
      // setUser({ ...user, loginStatus: true });
      // setUser(JSON.parse(atob(token.split('.')[1])));
    } else {
      // setUser({ loginStatus: false });
      localStorage.setItem('LP_PORTAL_USER', null);
    }
    // console.log(user);
  }, [user.loginStatus]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);

    if (!params.get('locale')) {
      setLang('zh-cn');
    }

    if (params.get('locale') === 'en') {
      setLang('en');
      setLocale('zh-cn');
    } else {
      setLocale('en');
      setLang('zh-cn');
    }
  }, [props.location]);

  const handleSelect = (e) => {
    setSelectedOption(e.target.value);
    history.push(`?locale=${e.target.value}`);
  };

  return (
    <div className="header">
      <div className="container">
        <div className="logo">
          <img className="logo_default" src={Logo} />
          <img className="logo_small" src={Logo_Small} />
        </div>
        {/*<div className="search-bar">*/}
        {/*<input />*/}
        {/*<Button color="primary">*/}
        {/*<FormattedMessage id="header.search" />*/}
        {/*</Button>*/}
        {/*</div>*/}
        <div className="action-bar">
          {/* <select
            className="language"
            onChange={handleSelect}
            value={selectedOption}
          >
            {Object.keys(localeMap).map((item) => (
              <option key={item} value={item}>
                {localeMap[item]}
              </option>
            ))}
          </select> */}
          {/* <Link to={`?locale=${locale}`}><span className="current">{localeMap[locale]}</span></Link> */}
          {/* <span>
              <span className="user-name">欢迎登录</span>
              <span onClick={handleLogOut} className="user-logout">
                退出
              </span>
            </span> */}
          {/* <LoginModal isOpen={modalOpen} onModalClose={handleModalClose} /> */}
          <div style={{display:'none'}} className="languageSelect">
            <div
              className="displayArea"
              onClick={(e) => {
                setLanguageSelectOpen(!languageSelectOpen);
              }}
            >
              <span>简体中文</span>
              {!languageSelectOpen && <i className="arrow down"></i>}
              {languageSelectOpen && <i className="arrow up"></i>}
            </div>
            <LanguageSelectMenu
              languageSelectOpen={languageSelectOpen}
              closeMenu={() => {
                setLanguageSelectOpen(false);
              }}
            ></LanguageSelectMenu>
          </div>

          <div className="userInfo">
            <div
              className="displayArea"
              onClick={(e) => {
                setUserProfileMenuOpen(!userProfileMenuOpen);
              }}
            >
              <div className="avatarArea">
                <img className="avatar" src={user.avatar} />
              </div>
              <div className="userName">{user?.nameCn || 'User'}</div>
            </div>
            <UserProfileMenu
              userProfileMenuOpen={userProfileMenuOpen}
              closeMenu={() => {
                setUserProfileMenuOpen(false);
              }}
            ></UserProfileMenu>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Header);
