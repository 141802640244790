import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation,
} from 'react-router-dom';
import classnames from 'classnames';
import { withRouter } from 'react-router';

import { NAV_MAP as navMap } from '../../constant';

import './style.less';

function Nav(props) {
  const getClassName = (link) => {
    const location = useLocation();

    return classnames('nav-item', { current: location.pathname === link });
  };

  const renderNavList = (navMap) =>
    navMap.map((item) => (
      <Link
        to={{ pathname: item.link, search: props.location.search }}
        key={item.link}
        className={getClassName(item.link)}
      >
        {item.name}
      </Link>
    ));

  return (
    <div className="nav">
      <div className="container">{renderNavList(navMap)}</div>
    </div>
  );
}

export default withRouter(Nav);
