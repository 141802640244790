import React, { Fragment } from 'react';

import VideoPlayer from '../../components/VideoPlayer';
import './introduction.less';
import videoImg from '../../images/homepage_video_image.jpg';
import { VIDEO_LIST_MAIN_INTRO } from '../../constant/data';
import { FormattedMessage } from 'react-intl';

function Introduction() {
  
  return (
    <div className="introduction">
      <div className="container">
        <div className="title"><FormattedMessage id="introduction.title" /></div>
        <div className="video">
          <VideoPlayer
            autoplay={false}
            controls={true}
            sources={[
              {
                src: `${VIDEO_LIST_MAIN_INTRO}`,
                type: 'video/mp4',
              },
            ]}
            poster={videoImg}
          />
        </div>
      </div>
    </div>
  );
}

export default Introduction;
