import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import {
  RecoilRoot
} from 'recoil';

import {ToastContainer, Slide} from 'react-toastify';
import MyRouter from './pages/myRouter';

import {
  About,
  Curriculum,
  Discover,
  Home,
  Me,
  Scratch,
  CurriculumDetail,
  Live,
  LoginPage,
} from './pages';

import './styles/ReactToastify.less';
import './styles/index.less';

export default (mountNode) => {
  function App() {
    return (
      <RecoilRoot>
        <Router>
          <ToastContainer
            position="top-right"
            autoClose={1500}
            hideProgressBar={true}
            newestOnTop={false}
            transition={Slide}
          />
          <Switch>
            <Route path="/login" component={LoginPage}/>
            <MyRouter path="/curriculum" component={Curriculum}/>
            <MyRouter path="/curriculum-detail" component={CurriculumDetail}/>
            <MyRouter path="/curriculum-live" component={Live}/>
            <MyRouter path="/discover" component={Discover}/>
            <MyRouter path="/me" component={Me}/>
            <MyRouter path="/about" component={About}/>
            <MyRouter path="/scratch" component={Scratch}/>
            <MyRouter path="/" component={Home}/>
            <Redirect to="/login"></Redirect>
          </Switch>
        </Router>
      </RecoilRoot>
    );
  }

  ReactDOM.render(<App/>, mountNode);
};
