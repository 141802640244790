import {
  atom
} from 'recoil';
const user = JSON.parse(localStorage.getItem('LP_PORTAL_USER'));
const userInfo = atom({
  key: 'userInfo',
  default: user ? user : {}
});

const langState = atom({
  key: 'langState',
  default: 'zh-cn'
});

const liveState = atom({
  key: 'liveState',
  default: {
    floatWindow: true,
    data: {}
  }
    // "appName": "primary",
    // "streamName": "HdNhn",
    // "liveName": "scratch",
    // "liveCourseDescribe": "scratch desc",
    // "cover": "https://littleprogrammer-live.oss-cn-shanghai.aliyuncs.com/upload/1/%E5%88%9D%E7%BA%A7%E4%BD%9C%E5%93%81%E5%B0%81%E9%9D%A2.PNG",
    // "pullLiveUrl": null,
    // "push_live_url": null,
    // "review_live_url": null,
    // "duration": null,
    // "remark": null,
    // "createTime": "",
    // "modifyTime": "",
    // "createUser": 0,
    // "modifyUser": 0
});

export {
  userInfo,
  langState,
  liveState
};
