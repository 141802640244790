import React, { Fragment } from 'react';

import Section from '../../components/Section';
import { Card } from '../../components/Card';

import Img1 from "../../images/pictures_proceed/img_1.jpg";
import Img2 from "../../images/pictures_proceed/img_2.jpg";
import Img3 from "../../images/pictures_proceed/img_3.jpg";

function Recommend() {
  return (
    <Section className="discover-recommend">
      <div className="divide">
        <div className="line"></div>
        <div>You may also like…</div>
        <div className="line"></div>
      </div>
      <div className="section-content">
        <Card
          title="Introduction to programming"
          description="Majority of peole will work in jobs that don’t exist today."
          date="02 May"
          image={Img1}
        />
        <Card
          title="Building animation with a story"
          description="Majority of peole will work in jobs that don’t exist today."
          date="02 May"
          image={Img2}
        />
        <Card
          title="Create your game world"
          description="Majority of peole will work in jobs that don’t exist today."
          date="02 May"
          image={Img3}
        />
      </div>
    </Section>
  );
}

export default Recommend;
