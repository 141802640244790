import React, {useState, useEffect } from 'react';
import BasicModal from '../Modal/index';
import './EditModal.less';
import Button from '../Button';
import { FormattedMessage } from 'react-intl';

function EditModal(props) {
  const { isOpen, confirmEdit, cancelEdit, info, modaltTitle, infoType } = props;
  const [modalEdit, setmodalEdit] = useState(info);
  useEffect(() => {
    if (isOpen) {
      setmodalEdit(info);
    }
  }, [isOpen]);


  const handleConfirm = () => {
    confirmEdit(modalEdit)
  };

  const handleCancel = () => {
    cancelEdit();
  };

  const handleInputInfo = (e) => {
    setmodalEdit(e.target.value);
  };



  return (
    <BasicModal {...props} isOpen={isOpen} class="EditModal">
      <div className="modal-title">
        <span>修改</span>{modaltTitle}
      </div>
      <div className="text-input">
        <input type="number" onChange={(e) => handleInputInfo(e)} value={modalEdit} name="info" />
        <select name="" id="">
          <option value="0">女</option>
          <option value="1">男</option>
        </select>
      </div>
      <div className="button-group">
        <Button color="primary" handleClick={handleConfirm}>
          <FormattedMessage id="commendModal.confirm" />
        </Button>
        <Button color="outline" handleClick={handleCancel}>
          <FormattedMessage id="commendModal.cancel" />
        </Button>
      </div>
    </BasicModal>
  );
}

export default EditModal;
