import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import axios from 'axios';
export default class MyRouter extends Component {
  render() {
    let token = localStorage.getItem('LP_PORTAL_TOKEN');
    axios.defaults.headers.common['token'] = token;

    return (
      <div>
        {token ? (
          <Route {...this.props}></Route>
        ) : (
          <Redirect to="/login"></Redirect>
        )}
      </div>
    );
  }
}
