import About from './About';
import Curriculum from './Curriculum';
import Discover from './Discover';
import Home from './Home';
import Me from './Me';
import Scratch from './Scratch';
import CurriculumDetail from './CurriculumDetail';
import Live from './Live';
import LoginPage from './LoginPage';


export {
  About,
  Curriculum,
  Discover,
  Home,
  Me,
  Scratch,
  CurriculumDetail,
  Live,
  LoginPage
}
