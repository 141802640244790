import React, {useState, useEffect} from 'react';
import BasicModal from '../Modal/index';
import Button from '../Button';
import { FormattedMessage } from 'react-intl';

import './ConfirmModal.less';

function ConfirmModal(props) {
  const { handleDel, isOpen, cancelOrClose} = props;

  const handleCancel = () => {
    cancelOrClose(false)
  };

  const onModalClose = () => {
    cancelOrClose(false)
  };

  return (
    <BasicModal {...props} isOpen={isOpen} class="confirm-modal" onModalClose={onModalClose}>
      <div className="title">你确定要删除学习路径吗?</div>
      <div className="content">这过程将会被保存</div>
      <div className="button-footer">
        <Button handleClick={handleDel} color="primary" size="small">
          删除
        </Button>
        <Button color="default" size="small" handleClick={handleCancel}>
          <FormattedMessage id="notificationModal.cancel" />
        </Button>
      </div>
    </BasicModal>
  );
}

export default ConfirmModal;
