import React, { Fragment } from 'react';
import './style.less';

import VideoPlayImg from '../img/video_play.png';

function LearningPathItemDetail(props) {
    const { learningPathItemDetailId, selectedLearningPathItemIndex, selectedLearningPathDetailItems, learningPathDetailItemOnClick, selectedLearningPathId, collectLearnPath } = props;
    let displayControlFlag = false;
    if (Math.floor(selectedLearningPathItemIndex / 2) === learningPathItemDetailId) {
        displayControlFlag = true;
    }

  const addToMyLearnPath = () => {
      collectLearnPath(selectedLearningPathId, 1)
    };

    return (
        <Fragment>
            {displayControlFlag && (
                <div className={`learningPathItemDetailContainer ${selectedLearningPathItemIndex % 2 === 0 ? 'left' : 'right'}`}>
                    <ul className="itemUl">
                        {selectedLearningPathDetailItems &&
                            selectedLearningPathDetailItems.length &&
                            selectedLearningPathDetailItems.length > 0 &&
                            selectedLearningPathDetailItems.map((detailItem, index) => {
                                return (
                                    <li
                                        key={detailItem.id}
                                        className={`itemLi ${index % 2 === 0 ? 'odd' : 'even'}`}
                                        onClick={() => {
                                            learningPathDetailItemOnClick(detailItem);
                                        }}
                                    >
                                        <img className="videoPlayImg" src={VideoPlayImg}></img>
                                        <div className="text">
                                            <div className="title">{detailItem.courseName}</div>
                                            <div className="description">{detailItem.courseDescribe}</div>
                                        </div>
                                    </li>
                                );
                            })}
                        <li className="addClickArea" onClick={() => addToMyLearnPath()}>
                            <div className="clickIcon">&nbsp;</div>
                            <div className="clickText">添加我的学习路线</div>
                        </li>
                    </ul>
                </div>
            )}
        </Fragment>
    );
}

export default LearningPathItemDetail;
