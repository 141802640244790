import React from 'react';

import QRCodeImg from '../../images/qr-code.png';
import LikeImg from '../../images/like.png';

import './style.less';

function Footer() {
  return (
    <div className="footer">
      <div className="container">
        <div className="content">
          {/*<div className="web-map">*/}
            {/*<img src={QRCodeImg} />*/}
            {/*<div className="list">*/}
              {/*<div className="title">Product</div>*/}
              {/*<ul>*/}
                {/*<li>Landingpage</li>*/}
                {/*<li>Features</li>*/}
                {/*<li>Documentation</li>*/}
                {/*<li>Referral Program</li>*/}
                {/*<li>Pricing</li>*/}
              {/*</ul>*/}
            {/*</div>*/}
            {/*<div className="list">*/}
              {/*<div className="title">Services</div>*/}
              {/*<ul>*/}
                {/*<li>Documentation</li>*/}
                {/*<li>Design</li>*/}
                {/*<li>Themes</li>*/}
                {/*<li>Coding</li>*/}
              {/*</ul>*/}
            {/*</div>*/}
          {/*</div>*/}
          <div className="copyright">
            <a className="beian" href="https://beian.miit.gov.cn">陕ICP备2021004489号-1</a>
            {/*<img src={LikeImg} />*/}
            <div>Copyright © 2021. Designed with love from UCD.</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;
