import React from 'react';
import BasicModal from '../Modal/index';
import Button from '../Button';
import { FormattedMessage } from 'react-intl';

import './NotificationModal.less';

function NotificationModal(props) {
  const { handleContinue, handleCancel} = props;

  
  return (
    <BasicModal {...props} class="notification-modal">
      <div className="modal-title">{props.title}</div>
      <div className="modal-content">{props.content}</div>
      <div className="basic-modal-footer">
        <Button handleClick={handleContinue} color="danger" size="small">
          <FormattedMessage id="notificationModal.confirm" />
        </Button>
        <Button color="default" size="small" handleClick={handleCancel}>
          <FormattedMessage id="notificationModal.cancel" />
        </Button>
      </div>
    </BasicModal>
  );
}

export default NotificationModal;
