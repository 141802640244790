import React, { useState, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { liveState } from '../../atom';

function LiveVideo() {
  const [liveInfo, setLiveInfo] = useRecoilState(liveState);
  // console.log(liveInfo);

  useEffect(() => {
    const player = new Aliplayer(
      {
        id: 'live_player',
        width: '100%',
        height: '600px',
        autoplay: true,
        isLive: true,
        source: liveInfo.data.pullLiveUrl,
      },
      function (player) {
        console.log('播放器创建好了。');
      }
    );
    return () => {
      player.dispose();
    };
  }, []);

  return <div id="live_player" className="prism-player"></div>;
}

export default LiveVideo;
