import React from 'react';
import classnames from 'classnames';

import './style.less';

function Section({ title, isGrey, children, description, className }) {
  return (
    <div
      className={classnames(className, 'section', {
        'section-grey': !!isGrey
      })}
    >
      <div className="container">
        <div className="title">{title}</div>
        <div className="content">
          {children}
        </div>
        <div className="description">{description}</div>
      </div>
    </div>
  );
}

export default Section;
