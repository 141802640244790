import React, { useState, Fragment } from 'react';
import './style.less';

function learningPathItem(props) {
    const {item, deleteLearnPath} = props;
    const handleDelete = (e) => {
      e.stopPropagation()
      deleteLearnPath(item.id)
    };
    return (
        <div
            className={`learningPathItemContainer ${item.id === props.selectedLearningPathItem?.id ? 'selectedItem' : ''}`}
            onClick={() => {
                props.learningPathItemOnClick(item, props.itemIndex);
            }}
        >
            <div className="contentContainer">
                <img className="itemImg" src={item.cover} />
                <div className="itemText">
                    <div className="title">{item.pathName}</div>
                    <div className="description">{item.pathDescribe}</div>
                </div>
            </div>
          <div onClick={(e) => handleDelete(e)} className="delete-button"/>
        </div>
    );
}

export default learningPathItem;
