import React from "react";
import classnames from "classnames";

import "./media.less";

import AvatarImg from "../../images/avatar.png";

function Media({
  title,
  description,
  isSelected,
  avatarSrc = AvatarImg,
  avatarSize = "normal",
  handleClick,
}) {
  return (
    <div
      className={classnames("media", { current: !!isSelected })}
      onClick={(id) => handleClick && handleClick(id)}>
      <div className={classnames("avatar", `avatar-${avatarSize}`)}>
        <img src={avatarSrc} />
      </div>
      <div className="summary">
        <div className="title">{title}</div>
        <div className="description">
          {description}
        </div>
      </div>
    </div>
  );
}

export default Media;
