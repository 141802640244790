import React, { useEffect, useRef } from 'react';
import videojs from 'video.js';
// import 'video.js/dist/video-js.min.css';

import './style.less';

function VideoPlayer(props) {
  let player;
  const playerRef = useRef();

  useEffect(() => {
    player = videojs(playerRef.current, { ...props, fluid: true });

    return () => {
      player.pause();
      // player.dispose();
    };
  });

  return (
    <div data-vjs-player>
      <video ref={playerRef} className="video-js vjs-big-play-centered"></video>
    </div>
  );
}

export default VideoPlayer;
