import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import Default from '../../images/card-default.png';
import './card.less';

function Card({ title, description, date, image, handleClick, flexStyle }) {
  return (
    <div
      className={flexStyle ? 'card flexStyle' : 'card'}
      onClick={() => handleClick && handleClick()}
    >
      <div className="card-banner" css={{
        backgroundImage: `url(${image ? image : Default})`
      }}></div>
      <div className="information">
        <div className="title">{title}</div>
        <div className="description">{description}</div>
        <div className="date">{date}</div>
      </div>
    </div>
  );
}

export default Card;
