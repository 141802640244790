import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import './style.less';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import Button from '../../../components/Button';
import icons_recomman from '../../../images/icons_recomman.svg';
import icons_following from '../../../images/icons_following.svg';
import icon_hot from '../../../images/icons_hot.svg';
import icons_recomman_selected from '../../../images/icons_recomman_selected.svg';
import icons_following_selected from '../../../images/icons_following_selected.svg';
import icon_hot_selected from '../../../images/icons_hot_selected.svg';

import axios from 'axios';
import {APIS} from '../../../services/api';
import { useHistory } from 'react-router-dom';

const Channels = () => {
    const [selectedTab, setSelectTab] = useState('init');
    const [workList, setWorkList] = useState([]);
    const [oneUserCommentList, setOneUserCommentList] = useState({});
    const [modalWorkComment, setModalWorkComment] = useState({});
    const history = useHistory();

  const handleInputComment = (e, workId) => {
    const textValue = e.target.value;
    const cloneText = _.cloneDeep(modalWorkComment);
    cloneText[workId] = textValue;
    setModalWorkComment(cloneText);
  };

  const handlePublishComment = (workId, workLikesCount, remark, staffId) => {
    axios({
      method: 'GET',
      url: APIS.COMMENT_USER_WORKS,
      headers: {
        'token': localStorage.getItem('LP_PORTAL_TOKEN')
      },
      params: {
        "commentLikes": workLikesCount,
        "id": workId,
        "parentId": 0,
        "remark": remark || '',
        "staffId": staffId,
        "workComment": modalWorkComment[workId],
        "workId": workId
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

    const getWorkList = () => {
      axios({
        method: 'GET',
        url: APIS.GET_WORK_LIST,
        headers: {
            'token': localStorage.getItem('LP_PORTAL_TOKEN')
        },
        params: {
          page: 1,
          limit: 100,
          //nameCn: "",
          orderByType: 1
        },
      })
        .then((res) => {
          const response = res.data.page.list;
          setWorkList(response)
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getHotWorkList = () => {
      axios({
        method: 'GET',
        url: APIS.GET_HOT_WORK_LIST,
        headers: {
            'token': localStorage.getItem('LP_PORTAL_TOKEN')
        }
      })
        .then((res) => {
          const response = res.data.resp;
          setWorkList(response)
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getOneUserCommentList = (workId) => {
      axios({
        method: 'GET',
        url: APIS.GET_ONEUSER_WORK_COMMENT_LIST,
        headers: {
          'token': localStorage.getItem('LP_PORTAL_TOKEN')
        },
        params: {
          workId: workId,
        },
      })
        .then((res) => {
          const userCommentList = res.data.resp;
          const cloneUserComment = _.cloneDeep(oneUserCommentList);
          cloneUserComment[workId] = userCommentList;
          setOneUserCommentList(cloneUserComment)
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const callHandleWorkLike = (staffId, workId) => {
      axios({
        method: 'GET',
        url: APIS.LIKE_USER_WORDS,
        headers: {
          'token': localStorage.getItem('LP_PORTAL_TOKEN')
        },
        params: {
          staffId: staffId,
          workId: workId,
        },
      })
        .then((res) => {
          toast.success('点赞成功!');
          getWorkList();
        })
        .catch((error) => {
          console.log(error);
        });
    }

	const DateFormater = (longTime) => {
		const dt = Number(longTime);
		if(dt){
		  const date = new Date(dt);
		  const month = (date.getMonth() + 1).toString().padStart(2, '0');
		  const day = date.getDate().toString().padStart(2, '0');
		  const hour = date.getHours().toString().padStart(2, '0');
		  const minute = date.getMinutes().toString().padStart(2, '0');
		  const second = date.getSeconds().toString().padStart(2, '0');
		  return `${date.getFullYear()}-${month}-${day} ${hour}:${minute}:${second}`;
		}
	};

  const selectedAll = () => {
    setSelectTab('all');
    getWorkList();
  };

    //根据ID记录展开或者收起
    const [commentExtendStatus, setCommentExtendStatus] = useState({});

    const handleComment = (id) => {
      const status = _.cloneDeep(commentExtendStatus);
      status[id] = !status[id];
      setCommentExtendStatus(status);
      getOneUserCommentList(id)
    };

    const handleWorkLikes = (staffId, workId) => {
      callHandleWorkLike(staffId, workId)
    };

    const handleLike = (id) => {

    };

    const handleWatch = (id) => {

    };

    const handleOnClickReply = () => {
      console.log('reply');
    };

    const replyAndLike = () => {
      return (
        <div>
          <span className="reply" onClick={() => handleReply()}>Reply</span>
          <span className="like" onClick={() => handleLike()}>123,456</span>
        </div>
      )
    };

    const renderPreLook = () => {
      return (
        <div onClick={() => handleOnClickReply()} className="preLook">
          <span className="userName">Clarence Roberts</span>
          <span>and others</span>
          <span className="userName">324 replies</span>
        </div>
      )
    };

    const renderCharList = () => {
      return (
        <ul className="chatList">
          <li>
            <div className="user">
              <div>
                <div className="userName">Dora Perez</div>
                <div className="title">Your work is really impressive!</div>
              </div>
              <div className="date">
                <div>04 Jul 2021   20:34</div>
                {replyAndLike()}
              </div>
            </div>
          </li>
        </ul>
      )
    };

  const handleItemClick = (item) => {
    let itemCopy = JSON.parse(JSON.stringify(item));
    itemCopy.isPlayOnly = true;
    let query = {
        pathname: '/scratch',
        query: { worksItem: itemCopy },
    };
    history.push(query);
  };


    return (
        <div className="channels-container">
            <div className="tab-area"
              onLoad= {() => {
                selectedTab === 'init' ? selectedAll() : () => {}
              }}
            >
                <ul className="tab-ul">
                    <li
                        className={selectedTab === 'all' ? 'tab-li tab-li-selected' : 'tab-li tab-li-unselected'}
                        onClick={() => {
                            selectedAll()
                        }}
                    >
                        <img align="absmiddle" src={selectedTab === 'all' ? icons_recomman_selected : icons_recomman} />
                        <span>所有信息</span>
                    </li>
                    <li
                        style={{display:'none'}}
                        className={selectedTab === 'subscribed' ? 'tab-li tab-li-selected' : 'tab-li tab-li-unselected'}
                        onClick={() => {
                            setSelectTab('subscribed');
                        }}
                    >
                        <img align="absmiddle" src={selectedTab === 'subscribed' ? icons_following_selected : icons_following} />
                        <span>订阅信息</span>
                    </li>
                    <li
                        style={{display:'none'}}
                        className={selectedTab === 'hot' ? 'tab-li tab-li-selected' : 'tab-li tab-li-unselected'}
                        onClick={() => {
                            setSelectTab('hot');
                            getHotWorkList();
                        }}
                    >
                        <img align="absmiddle" src={selectedTab === 'hot' ? icon_hot_selected : icon_hot} />
                        <span>热门</span>
                    </li>
                </ul>
            </div>
            <div className="feeds-area">
              {workList &&
                workList.map((item, index) => {
                  return (
                    <div className="feeds-tab">
                        <div className="feeds-content">
                            <div className="feeds-work-avatar">
                                <img src={item.workCover} />
                            </div>
                            <div className="feeds-work-info">
                                <div className="feeds-work-info-name">{item.workName}</div>
                                <div className="feeds-work-info-comment">{item.workDescribe}</div>
                                <div className="feeds-work-info-student">
                                  <div className="avatar"><img src={item.avatar} /></div>&nbsp;&nbsp;
                                  {item.nameCn}&nbsp;&nbsp;&nbsp;&nbsp;{item.schoolName + item.gradeName + item.className}
                                </div>
                                <div className="feeds-work-info-time">上传时间: {DateFormater(item.createTime)}</div>
                                <div className="action">
                                  <span onClick={() => handleComment(item.id, item.workLikesCount, item.remark, item.staffId)}>
                                    <i className="icon icon-comment"></i>
                                    <span><FormattedMessage id="work.comment" /></span>
                                  </span>
                                  <span>
                                    <i className="icon icon_watched"></i>
                                    <span><FormattedMessage id="work.watch" /></span>
                                    <span>{item.workViewCount}</span>
                                  </span>
                                  <span onClick={() => handleWorkLikes(item.staffId, item.id)}>
                                    <i className="icon icon-like"></i>
                                    <span><FormattedMessage id="work.like" /></span>
                                    <span>{item.workLikesCount}</span>
                                  </span>
                                </div>
                            </div>
                            <div className="feeds-watch-work"
                              key={index}
                              onClick={() => {
                                  handleItemClick(item);
                              }}
                            >
                                <span>
                                  <i className="icon icon_watched"></i>
                                  <span> <FormattedMessage id="work.watch.link" /></span>
                                </span>
                            </div>
                        </div>
                        {/* -comment start- */}
                        {commentExtendStatus[item.id] && (
                        <div className="comment">
                          <div className="comment-area">
                            <div className="avatar">
                              <img src="https://littleprogrammer-live.oss-cn-shanghai.aliyuncs.com/workspace/f97f95d6cca741869f85cc010a10c96f.JPG" alt=""/>
                              <textarea onChange={(e) => handleInputComment(e, item.id)} value={modalWorkComment[item.id] || ''} name="comment" cols="30" rows="10"></textarea>
                            </div>
                            <div className="function">
                              <div>
                                <div className="addEmoji"><FormattedMessage id="channels.emoji" /></div>
                                <div className="addPic"><FormattedMessage id="channels.pic" /></div>
                              </div>
                              <Button handleClick={() => handlePublishComment(item.id, item.workLikesCount, item.remark, item.staffId)} color="primary"><FormattedMessage id="channels.publish" /></Button>
                            </div>
                            <div className="replyList">
                              <ul>
                                {oneUserCommentList[item.id] && oneUserCommentList[item.id].map((commnentList, index) => {
                                  return (
                                    <li>
                                      <img src="https://littleprogrammer-live.oss-cn-shanghai.aliyuncs.com/workspace/f97f95d6cca741869f85cc010a10c96f.JPG" alt="" className="avatar"/>
                                      <div className="user">
                                        <div>
                                          <div className="userName">Dora Perez</div>
                                          <div className="title">Your work is really impressive!</div>
                                        </div>
                                        <div className="date">
                                          <div>04 Jul 2021   20:34</div>
                                          {replyAndLike()}
                                        </div>
                                      </div>
                                      <div className="chat">
                                        {renderCharList()}
                                      </div>
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* -comment end- */}
                    </div>
                  );
                })
              }
            </div>
        </div>
    );
};
export default Channels;
