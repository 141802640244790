import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Button from '../../components/Button';
import { withRouter } from 'react-router';

function OnlineEdit(props) {
  const renderList = () => {
    const scratchList =
      JSON.parse(window.localStorage.getItem('scratch-list')) || {};
    return Object.keys(scratchList).map((file) => {
      return (
        <div className="file-item">
          <span className="file-name">{file}</span>
          <Link to={`/scratch?name=${file}`}>View</Link>
        </div>
      );
    });
  };

  return (
    <div className="online-edit-content">
      在线编程
      <h3>scratch list</h3>
      <div className="file-list">{renderList()}</div>
      <Button color="primary">
        <Link to={{ pathname: '/scratch', search: props.location.search }}>
          start coding
        </Link>
      </Button>
    </div>
  );
}

export default withRouter(OnlineEdit);
