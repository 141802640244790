import React from "react";
import './commentInput.less'
import Button from "../Button";

function CommentInput() {
  return (
    <div className="comment-form">
      <div className="title">What have you learnt today?</div>
      <div className="form-control">
        <textarea></textarea>
      </div>
      <div className="action">
        <div>
          <span className="action-item">
            <i className="icon icon-smile"></i>
            Emoji
          </span>
          <span className="action-item">
            <i className="icon icon-image"></i>
            Add photo
          </span>
          <span className="action-item">
            <i className="icon icon-hashtag"></i>
            Topics
          </span>
        </div>
        <Button color="primary">Publish</Button>
      </div>
    </div>
  );
}

export default CommentInput;