import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import classnames from 'classnames';
import closeIconSrc from '../../images/icon-close.png';
import './index.less';

function BasicModal(props) {
  const { isOpen } = props;
  const [modalOpenOwn, setModalOpenOwn] = useState(false);
  ReactModal.setAppElement('body');

  useEffect(() => {
    setModalOpenOwn(isOpen);
  }, [isOpen]);

  const handleModalClose = () => {
    props.onModalClose && props.onModalClose();
  };

  const handleModalCloseByIcon = () => {
    setModalOpenOwn(false)
    props.onModalClose && props.onModalClose();
  };

  const contentClassName = classnames('ReactModal__Content', props.class);

  return (
    <ReactModal
      contentLabel="Modal"
      {...props}
      isOpen={modalOpenOwn}
      onAfterClose={handleModalClose}
      className={contentClassName}
      overlayClassName="ReactModal__Overlay"
      htmlOpenClassName="ReactModal__Html--open"
      >
      <img
        src={closeIconSrc}
        className="modal-close"
        onClick={handleModalCloseByIcon}
      />
      {props.children}
    </ReactModal>
  );
}

export default BasicModal;
