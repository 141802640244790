import React from 'react';
import { FormattedMessage } from 'react-intl';

const NAV_MAP = [
  {
    name: <FormattedMessage id="nav.home" />,
    link: '/',
  },
  {
    name: <FormattedMessage id="nav.curriculum" />,
    link: '/curriculum',
  },
  {
    name: <FormattedMessage id="nav.discover" />,
    link: '/discover'
  },
  {
    name: <FormattedMessage id="nav.me" />,
    link: '/me',
  },
  {
    name: <FormattedMessage id="nav.aboutUs" />,
    link: '/about',
  },
];

const USER = {
  TEACHER: 0,
  STUDENT: 1,
};

const GENDER = {
  BOY: 1,
  GIRL: 0,
};

const WORK_STATUS = {
  DRAFT: 0,
  PUBLISHED: 1,
  REVIEWED: 2
};

export { NAV_MAP, USER, GENDER, WORK_STATUS };
