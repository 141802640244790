import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {useRecoilState} from 'recoil';
import {userInfo} from '../../atom';

import './style.less';
import EditModal from "../../components/Modal/EditModal";

function MyProfile(props) {
  const [user, setUser] = useRecoilState(userInfo);
  const {nameCn, gender, school, className, gradeName, age} = user;
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [info, setInfo] = useState('');
  const [infoType, setInfoType] = useState('');
  const handleEdit = (info) => {
    console.log(info);
    switch (info) {
      case 'nameCn':
        setTitle(<FormattedMessage id="myProfile.Name"/>);
        break;
      case 'gender':
        setTitle(<FormattedMessage id="myProfile.Gender"/>);
        break;
      case 'school':
        setTitle(<FormattedMessage id="myProfile.School"/>);
        break;
      case 'gradeName':
        setTitle(<FormattedMessage id="myProfile.Grade"/>);
        break;
      case 'className':
        setTitle(<FormattedMessage id="myProfile.Class"/>);
        break;
      case 'age':
        setTitle(<FormattedMessage id="myProfile.Age"/>);
        break;
    }
    setInfoType(info);
    setModalOpen(true)
  };

  const handleConfirmInfo = () => {
    setModalOpen(false)
  };

  const handleCancelInfo = () => {
    setModalOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };


  return (
    <div className="my-profile">
      <ul>
        <li>
          <span className="title"><FormattedMessage id="myProfile.Name"/></span>
          <span className="value">{nameCn}</span>
          {/* hide for temporary */}
          {/* <div className="edit" onClick={() => handleEdit('nameCn')} /> */}
        </li>
        <li>
          <span className="title"><FormattedMessage id="myProfile.Gender"/></span>
          <span className="value">{gender === 0 ? '女' : '男'}</span>
          {/* <div className="edit" onClick={() => handleEdit('gender')} /> */}
        </li>
        <li>
          <span className="title"><FormattedMessage id="myProfile.School"/></span>
          <span className="value">{school}</span>
          {/* <div className="edit" onClick={() => handleEdit('school')} /> */}
        </li>
        <li>
          <span className="title"><FormattedMessage id="myProfile.Grade"/></span>
          <span className="value">{gradeName}</span>
          {/* <div className="edit" onClick={() => handleEdit('gradeName')} /> */}
        </li>
        <li>
          <span className="title"><FormattedMessage id="myProfile.Class"/></span>
          <span className="value">{className}</span>
          {/* <div className="edit" onClick={() => handleEdit('className')} /> */}
        </li>
        <li>
          <span className="title"><FormattedMessage id="myProfile.Age"/></span>
          <span className="value">{age}</span>
          {/* <div className="edit" onClick={() => handleEdit('age')} /> */}
        </li>
      </ul>
      {/* hide for temporary */}
      {/* <EditModal
        isOpen={modalOpen}
        onModalClose={handleModalClose}
        confirmEdit={handleConfirmInfo}
        cancelEdit={handleCancelInfo}
        info={info}
        modaltTitle={title}
        infoType={infoType}
      /> */}
    </div>
  );
}

export default MyProfile;
