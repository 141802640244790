import React, { Fragment, useState } from 'react';
import ReactDOM from 'react-dom';

import AppLayout from '../AppLayout';
import Banner from '../Home/Banner';
import { Tabs, TabPane } from '../../components/Tab';
import LiveVideo from '../../components/LiveVideo/LiveVedio';

import './style.less';

function Live() {
  return (
    <AppLayout>
      <section className="container">
        <div className="live-title">Scratch 的故事</div>
        <div className="live-container">
          <LiveVideo />
        </div>
      </section>
    </AppLayout>
  );
}

export default Live;
