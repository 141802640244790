import React, {useState, useEffect, useRef } from 'react';
import BasicModal from '../Modal/index';
import './avatorUploadModal.less';
import Button from '../Button';
import { FormattedMessage } from 'react-intl';
import axios from 'axios';
import { toast } from 'react-toastify';
import {APIS} from "../../services/api";
function AvatorUploadModal(props) {
  const { isOpen, cancelChange } = props;
  const uploadInputRef = useRef();
  const [uploadFileName, setUploadFileName] = useState();
  const avatorImg = useRef();
  const littleAvatorImg = useRef();

  function clearModelState() {
    setUploadFileName(null);
    cancelChange()
  }

  function sendImgToServer({ file, fileName }) {
    const userInfo = JSON.parse(localStorage.getItem('LP_PORTAL_USER'));

    var myHeaders = new Headers();

    myHeaders.append('token', localStorage.getItem('LP_PORTAL_TOKEN'));

    var formdata = new FormData();
    formdata.append('file', file, fileName);

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };

    toast.info('正在上传...');

    fetch(APIS.UPLOAD_WORK_WHOLE_PATH, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        const workUrl = result?.resp;
        axios
          .post(APIS.UPLOAD_AVATOR, {
            avatar: workUrl,
            nameCn: userInfo.nameCn,
            staffId: userInfo.staffId,
            userId: userInfo.id,
            userType: userInfo.userType,
          })
          .then((response) => {
            if (response?.data.msg === 'success') {
              toast.success('上传成功!');
              clearModelState();
            }
          });
      })
      .catch((error) => {
        toast.error('发生了一些问题，上传失败，请重试');
      });
  }

  const handleConfirm = () => {
    sendImgToServer({
      file: uploadInputRef.current?.files[0],
      fileName: uploadFileName,
    })
  };

  const handleCancel = () => {
    cancelChange()
  };

  const handleZoomUp = () => {
    toast.info('敬请期待');
  };

  const handleZoomDown = () => {
    toast.info('敬请期待');
  };

  const picRefresh = () => {
    toast.info('敬请期待');
  };

  const handleOpenUpload = (e) => {
    uploadInputRef.current?.click();
  };

  const uploadOnChange = (e) => {
    const file = e?.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onprogress = function (e) {
      setUploadFileName(file.name);
    };
    fileReader.onload = function (e) {
      avatorImg.current.src = e.target.result;
      littleAvatorImg.current.src = e.target.result;
    }
  };


  return (
    <BasicModal {...props} isOpen={isOpen} class="avatorUploadModal">
      <div className="title">
        <FormattedMessage id="avatorModal.title" />
      </div>
      <div className="picArea">
        <div className="leftPic">
          <div className="net">
            <span/>
            <span/>
            <span/>
            <span/>
            <span/>
            <span/>
            <span/>
            <span/>
            <span/>
          </div>
          <img ref={avatorImg} id="file1_img" src="" alt=""/>
        </div>
        <div className="rightPic">
          <img ref={littleAvatorImg} id="file2_img" src="" alt=""/>
        </div>
        <div className="preview">
          <FormattedMessage id="avatorModal.preview" />
        </div>
      </div>
      <div className="functionArea">
        <input
          ref={uploadInputRef}
          accept="image/*"
          className="upload-work"
          type="file"
          name="uploadWork"
          id="uploadWork"
          onChange={uploadOnChange}
        />
        <Button
          className="upload-button"
          color="outline-white"
          handleClick={handleOpenUpload}
        >
          <FormattedMessage id="avatorModal.upload" />
        </Button>
        <div className="functionZoom">
          <div onClick={() => handleZoomUp()} className="zoomUp"/>
          <div onClick={() => handleZoomDown()} className="zoomDown"/>
          <div onClick={() => picRefresh()} className="picRefresh"/>
        </div>
      </div>
      <div className="tip">
        <FormattedMessage id="avatorModal.tip" />
      </div>
      <div className="button-group">
        <Button color="primary" handleClick={handleConfirm}>
          <FormattedMessage id="avatorModal.confirm" />
        </Button>
        <Button color="outline" handleClick={handleCancel}>
          <FormattedMessage id="avatorModal.cancel" />
        </Button>
      </div>
    </BasicModal>
  );
}

export default AvatorUploadModal;
