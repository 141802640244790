import React, { useState, useEffect } from 'react';
import xhr from 'xhr';
import { useRecoilState } from 'recoil';

import BasicModal from '../Modal/index';
import Button from '../Button';
import './LoginModal.less';
import Checkbox from '../Checkbox';
import { toast } from 'react-toastify';
import encrypt from '../../constant/encrypt';
import axios from 'axios';
import { APIS } from '../../services/api';
import { userInfo } from '../../atom';
import { FormattedMessage } from 'react-intl';

function LoginModal(props) {
  const [userText, setUserText] = useState('');
  const [pwdText, setPwdText] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [user, setUser] = useRecoilState(userInfo);

  useEffect(() => {
    setIsModalOpen(props.isOpen);
  }, [props.isOpen]);

  const handleLogin = (e) => {
    // xhr(
    //   {
    //     method: 'post',
    //     json: {
    //       email: userText,
    //       password: pwdText,
    //     },
    //     uri: 'http://localhost:3001/login',
    //   },
    //   function (err, resp, body) {
    //     // check resp.statusCode
    //     if (err || resp.statusCode !== 200) {
    //       toast.warn('Something is wrong, please try it later');
    //     }

    //     setIsModalOpen(false);
    //     setUser(JSON.parse(atob(resp.body.accessToken.split('.')[1])));
    //     localStorage.setItem('token', resp.body.accessToken);
    //   }
    // );
    if (!userText || !pwdText) {
      return;
    }
    toast.info('Loging...');
    const encrypedUser = encrypt.encrypt(userText);
    const encrypedPwd = encrypt.encrypt(pwdText);

    axios
      .post(APIS.LOGIN, {
        password: encrypedPwd,
        staffId: userText,
      })
      .then((data) => {
        const { code, message, token, msg } = data?.data;
        console.log(data);
        switch (code) {
          case 0:
            toast.success('Login Success!');
            setIsModalOpen(false);
            localStorage.setItem('LP_PORTAL_TOKEN', token);
            // setUser({ loginStatus: true });
            axios.defaults.headers.common['token'] = token;
            break;
          case 10002:
            toast.error('Account or Password is Wrong, Please check it again');
            localStorage.removeItem('LP_PORTAL_TOKEN');
            // setUser({ loginStatus: false });
            break;
          default:
            toast.warn('Something is wrong, please try it later');
            // setUser({ loginStatus: false });
            break;
        }
      })
      .catch((err) => {
        toast.error('Something is wrong, please try it later');
        console.log(err);
      });
  };

  
  return (
    <BasicModal {...props} isOpen={isModalOpen} class="loginModal">
      <div className="modal-title">
        <FormattedMessage id="loginModal.welcomeBack" />
      </div>
      <div className="login-input-group">
        <div className="login-input-inner login-input-name">
          <div className="input-label input-name-label">
            <FormattedMessage id="loginModal.userName" />
          </div>
          <input
            type="text"
            className="input-login-usrname"
            type="text"
            onChange={(e) => {
              setUserText(e.target.value);
            }}
          />
        </div>
        <div className="login-input-inner login-input-password">
          <div className="input-label input-password-label">
            {' '}
            <FormattedMessage id="loginModal.password" />
          </div>
          <input
            type="text"
            className="input-login-password"
            type="password"
            onChange={(e) => {
              setPwdText(e.target.value);
            }}
          />
        </div>
        <div className="login-footer">
          <Checkbox
            label="loginModal.keepSignIn"
            className="login-keep-signin"
            id="login-keep-signin"
          />
          <button
            id="forget-password"
            className="btn-text-link forget-password"
          >
            <FormattedMessage id="loginModal.forgotPassword" />
          </button>
        </div>
      </div>
      <Button color="primary" handleClick={handleLogin}>
        <FormattedMessage id="menu.login" />
      </Button>
    </BasicModal>
  );
}

export default LoginModal;
