import React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { FormattedMessage } from 'react-intl';
import Default from '../../images/default.png';
import './fullcard.less';

function FullCard({ title, image }) {
  return (
    <div
      className="full-card"
      css={{
        backgroundImage: `url(${image ? image : Default})`
      }}
    >
      <div className="title">{title}</div>
      <div className="link">
        <FormattedMessage id="fullCard.learnMore" />
      </div>
    </div>
  );
}

export default FullCard;
