import React, { useState, useEffect } from 'react';
import BasicModal from '../Modal/index';
import Button from '../Button';
import './LiveVideoModal.less';
import liveAni from '../../images/live-ani-white.json';
import Lottie from 'react-lottie';
import axios from 'axios';
import { APIS } from '../../services/api';
import { useRecoilState } from 'recoil';
import { liveState } from '../../atom';
import { useHistory } from 'react-router-dom';
import closeWhite from '../../images/icon-close-white.png';

function LiveVideoModal(props) {
  const [liveInfo, setLiveInfo] = useRecoilState(liveState);
  const [localLiveState, setLocalLiveState] = useState({});
  let history = useHistory();

  const handleClick = () => {
    history.push(`/curriculum-live`);
  };

  const handleClose = () => {
    setLiveInfo({ data: liveInfo, floatWindow: false });
  };

  useEffect(() => {
    return
    liveInfo.floatWindow === true &&
      axios
        .get(APIS.LIVE_STATUS, {
          params: {
            liveName: 'scratch',
          },
        })
        .then(function (response) {
          setLiveInfo({ data: response.data?.resp, floatWindow: true });
          setLocalLiveState({ ...response.data?.resp });
        })
        .catch(function (error) {
          console.log(error);
        });
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: liveAni,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const renderContent = () => (
    <div className="live-window float-window">
      <header className="live-window-header">
        <span className="live-window-title">
          {localLiveState?.liveCourseDescribe}
        </span>
        <img
          onClick={handleClose}
          className="live-window-close"
          src={closeWhite}
          alt=""
        />
      </header>
      <section className="live-window-content">
        <img
          className="live-window-preview-img"
          src={localLiveState.cover}
          alt=""
        />
      </section>
      <footer onClick={handleClick} className="live-window-footer">
        <Lottie options={defaultOptions} width={36} height={26} margin={0} />
        <span>Live</span>
      </footer>
    </div>
  );

  return localLiveState?.pullLiveUrl?.length > 0 &&
    history.location.pathname !== '/curriculum-live' &&
    liveInfo.floatWindow === true
    ? renderContent()
    : null;
}

export default LiveVideoModal;
