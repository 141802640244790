import React, { useState, useEffect } from 'react';
import BasicModal from '../Modal/index';
import './commentModal.less';
import Button from '../Button';
import { FormattedMessage } from 'react-intl';

import PointSelected from '../../images/point_selected.png';
import PointUnSelected from '../../images/point_unselected.png';

function CommentModal(props) {
    const { isOpen, confirmComment, cancelComment, comment, workScore } = props;
    const [modalComment, setmodalComment] = useState('');
    const [workScoreEdited, setWorkScoreEdited] = useState(workScore);
    const [displayWorkScore, setDisplayWorkScore] = useState(0);

    useEffect(() => {
        if (isOpen) {
            setmodalComment(comment);
            setDisplayWorkScore(workScore);
        }
    }, [isOpen]);

    const handleConfirm = () => {
        confirmComment(modalComment, workScoreEdited);
    };

    const handleCancel = () => {
        cancelComment();
    };

    const handleInputComment = (e) => {
        setmodalComment(e.target.value);
    };
    return (
        <BasicModal {...props} isOpen={isOpen} class="commentModal">
            <div className="modal-title">
                <FormattedMessage id="commendModal.title" />
            </div>
            <div className="modal-subTitle">总体评价</div>
            <div
                className="modal-point"
                onMouseLeave={() => {
                    setDisplayWorkScore(workScoreEdited);
                }}
            >
                {new Array(5).fill().map((item, index) => {
                    if (index < displayWorkScore) {
                        return (
                            <img
                                key={index}
                                src={PointSelected}
                                onClick={() => {
                                    setWorkScoreEdited(index + 1);
                                }}
                                onMouseEnter={() => {
                                    setDisplayWorkScore(index + 1);
                                }}
                            />
                        );
                    } else {
                        return (
                            <img
                                key={index}
                                src={PointUnSelected}
                                onClick={() => {
                                    setWorkScoreEdited(index + 1);
                                }}
                                onMouseEnter={() => {
                                    setDisplayWorkScore(index + 1);
                                }}
                            />
                        );
                    }
                })}
            </div>
            <div className="modal-subTitle" style={{ margin: '10px 0px' }}>
                <FormattedMessage id="commendModal.text" />
            </div>
            <div className="text-input">
                <textarea onChange={(e) => handleInputComment(e)} value={modalComment || ''} name="comment"></textarea>
            </div>
            <div className="button-group">
                <Button color="primary" handleClick={handleConfirm}>
                    <FormattedMessage id="commendModal.confirm" />
                </Button>
                <div style={{ width: '20px' }}></div>
                <Button color="outline" handleClick={handleCancel}>
                    <FormattedMessage id="commendModal.cancel" />
                </Button>
            </div>
        </BasicModal>
    );
}

export default CommentModal;
