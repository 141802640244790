import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Button from '../../components/Button';
import noProfileSrc from '../../images/no-my-profile.png';

function NoWorksSection(props) {
  let history = useHistory();

  const changeRoute = () => {
    const link = `/scratch?locale=zh-cn`;
    history.push(link);
  };

  return (
    <div className="no-works-container">
      <img className="default-image" src={noProfileSrc} alt="" />
      <div className="default-tip-text">
        <FormattedMessage id="me.noWorksDefaultTipText" />
      </div>
      <Button color="primary" handleClick={changeRoute}>
        <FormattedMessage id="me.noWorksButton" />
      </Button>
    </div>
  );
}

export default NoWorksSection;
