import React, { Fragment, useState } from 'react';

import AppLayout from '../AppLayout';
import BannerCurriculum from '../Home/BannerCurriculum';
import { Tabs, TabPane } from '../../components/Tab';
import LearningPath from './learningPath/index';
import Course from './Course';
import OnlineEdit from './OnlineEdit';
import { FormattedMessage } from 'react-intl';
import './style.less';

function Curriculum() {
    const renderCurriculumBase = () => {
        return (
            <Tabs defaultActiveIndex={0}>
                <TabPane tab={<FormattedMessage id="curriculum.course" />}>
                    <Course />
                </TabPane>
                <TabPane tab={<FormattedMessage id="curriculum.learningPath" />}>
                    <LearningPath />
                </TabPane>
            </Tabs>
        );
    };

    return (
        <AppLayout>
            <BannerCurriculum />
            {renderCurriculumBase()}
        </AppLayout>
    );
}

export default Curriculum;
