import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

import AppLayout from '../AppLayout';
import Button from '../../components/Button';
import { Tabs, TabPane } from '../../components/Tab';
import { FormattedMessage } from 'react-intl';
import MyWork from './MyWork';
import MyProfile from './MyProfile';
import UploadWorkModal from '../../components/Modal/UploadWorkModal';
import LearningPath from './learningPath/index';
import { useRecoilState } from 'recoil';
import { userInfo } from '../../atom';
import { USER } from '../../constant';

import backgroundImageSrc from '../../images/me-background-image.png';
import AvatorUploadModal from '../../components/Modal/avatorUploadModal.js'
import './style.less';

function Me(props) {
  const [user, setUser] = useRecoilState(userInfo);
  const [userAvatorMaskDisplay, setUserAvatorMaskDisplay] = useState(false);
  const [uploadWorkState, setUploadWorkState] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const uploadWork = (e) => {
    setUploadWorkState(true);
  };

  const handleModalClose = (e) => {
    setUploadWorkState(false);
  };

  const handleAvatorUploadModalClose = () => {
    setModalOpen(false)
  };

  const openAvatorModal = () => {
    setModalOpen(true)
  };

  return (
    <AppLayout>
      <div className="me">
        <img className="background-image" src={backgroundImageSrc} alt="" />
        <div className="info-box">
          <img className="user-avator" src={user.avatar} alt="" />
          <div
            onClick={() => openAvatorModal()}
            className={`user-avator-mask ${
              userAvatorMaskDisplay ? 'show' : 'hide'
            }`}
            onMouseEnter={() => {
              setUserAvatorMaskDisplay(true);
            }}
            onMouseLeave={() => {
              setUserAvatorMaskDisplay(false);
            }}
          >
            <FormattedMessage id="me.changeAvator" />
          </div>
          <div className="user-name">{user?.nameCn || 'User'}</div>
        </div>
        <div className="container">
          {user.userType === USER.STUDENT && (
            <Button color="primary" handleClick={uploadWork}>
              <FormattedMessage id="me.uploadWork" />
            </Button>
          )}
          <Tabs defaultActiveIndex={0}>
            <TabPane
              tab={
                user.userType === USER.STUDENT ? (
                  <FormattedMessage id="me.myWork" />
                ) : (
                  <FormattedMessage id="me.studentWork" />
                )
              }
            >
              <MyWork />
            </TabPane>
            <TabPane tab={<FormattedMessage id="me.myProfile" />}>
              <MyProfile />
            </TabPane>
            <TabPane tab={<FormattedMessage id="me.learningPath" />}>
              <LearningPath />
            </TabPane>
            {/*<TabPane tab={<FormattedMessage id="me.myHistory" />}>*/}
            {/*History*/}
            {/*</TabPane>*/}
          </Tabs>
        </div>
      </div>
      <UploadWorkModal
        uploadWorkState={uploadWorkState}
        onModalClose={handleModalClose}
        setUploadWorkState={setUploadWorkState}
      />
      <AvatorUploadModal
        onModalClose={handleAvatorUploadModalClose}
        cancelChange={handleAvatorUploadModalClose}
        isOpen={modalOpen}
      />
    </AppLayout>
  );
}

export default withRouter(Me);
