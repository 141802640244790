import React, { useState, Fragment } from 'react';
import './style.less';

function learningPathItem(props) {
    const item = props.item;
    return (
        <div
            className={`learningPathItemContainer ${item.id === props.selectedLearningPathItem?.id ? 'selectedItem' : ''}`}
            onClick={() => {
                props.learningPathItemOnClick(item, props.itemIndex);
            }}
        >
            <div className="contentContainer">
                <img className="itemImg" src={item.cover} />
                <div className="itemText">
                    <div className="title">{item.pathName}</div>
                    <div className="description">{item.pathDescribe}</div>
                </div>
            </div>
        </div>
    );
}

export default learningPathItem;
