import JSEncrypt from 'jsencrypt';

const PublicKey =
  'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAJImZs7oo49PlqXMaEKWvYG4VCr1pH+cvcY3wTct2rgSufc9wFrF/8JOoD2aWAWj5U0ed0m2s6EweANSCAvBnkECAwEAAQ==';

const PrivateKey =
  'MIIBUwIBADANBgkqhkiG9w0BAQEFAASCAT0wggE5AgEAAkEAkiZmzuijj0+WpcxoQpa9gbhUKvWkf5y9xjfBNy3auBK59z3AWsX/wk6gPZpYBaPlTR53SbazoTB4A1IIC8GeQQIDAQABAkAcmITvIRe3l7P7NtaDiOkFc6KMUUnhajK7kPcuK7uq3jnZi+c/FcWNgM0n98zWAsJfzq4cs73pKpLYO5Egj6KNAiEA7WHfiA+2NP85g+zZ8/Khi5BfJFxziQA2fwTA4i9jGBsCIQCdnMeYPbSQLorTblbYMHv7+XUJ/X8BuWIGg5RqMgpA0wIgHuHSwNK6c56Yju8rU69HEO8RUZutdC9Q56lopoRfse0CIEu74QCbbd72LkkINPyu13StWnrRbyzh6mNc6HzazKh3AiAT+5i5HT/L11XT05ATQT+fUnor9HW1pQ2DdnJPun8sAw==';

const encrypt = new JSEncrypt();
encrypt.setPublicKey(PublicKey);
encrypt.setPrivateKey(PrivateKey);


export default encrypt;