import React, { Fragment } from 'react';

import Button from '../../components/Button';
import HomeBanner from '../../images/home-banner.png';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import './banner.less';

function Banner({ hasDivideLine }) {
  let history = useHistory();

  const changeRoute = () => {
    history.push('/curriculum');
  };

  return (
    <Fragment>
      <div className="banner">
        <div className="information">
          <div className="title">
            <FormattedMessage id="content.mainTitle" />
          </div>
          <div className="description">
            <FormattedMessage id="content.description" />
          </div>
          <div className="all-courses">
            <Button color="primary" handleClick={changeRoute}>
              <FormattedMessage id="menu.startNow" />
            </Button>
          </div>
        </div>
        <img src={HomeBanner} />
      </div>
      {hasDivideLine && <div className="divide-line"></div>}
    </Fragment>
  );
}

export default Banner;
