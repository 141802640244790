import React from "react";
import { map } from "lodash";

import "./commentCard.less";

function CommentCard({ avatar, name, date, description, images, index }) {
  return (
    <div className="comment" key={index}>
      <div className="comment-container">
        <img className="avatar" src={avatar} alt="avatar" />
        <div className="content">
          <div className="name">{name}</div>
          <div className="date">{date}</div>
          <div className="description">{description}</div>
          <div className="image-box">
            {images &&
              map(images, (itemSrc, index) => {
                return <img className="image-item" src={itemSrc} alt="images" key={index}/>;
              })}
          </div>
        </div>
      </div>
      <div className="action">
        <span>
          <i className="icon icon-share"></i>
          <span>Forward</span>
        </span>
        <span>
          <i className="icon icon-comment"></i>
          <span>Comment</span>
        </span>
        <span>
          <i className="icon icon-like"></i>
          <span>Like</span>
        </span>
      </div>
    </div>
  );
}

export default CommentCard;
