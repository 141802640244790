import React, { Fragment, useState, useEffect } from "react";
import { Media } from "../../components/Media";
import { get, map, keys, sortBy, forEach, values, reduce } from "lodash";
import classnames from "classnames";
import Scrollspy from "react-scrollspy";
import { timelineEvent } from "../../constant/utiles";

import "./style.less";

function prepareDara(timeLineData) {
  const years = sortBy(keys(timeLineData), (year) => -year);
  let months = {};
  forEach(years, (year) => {
    if (timeLineData[year]) {
      if (!months[year]) {
        months[year] = [];
      }
      forEach(keys(timeLineData[year]), (month) => {
        months[year].unshift(`${year}.${month}`);
      });
    }
  });
  const firstItem = years && months[years[0]] && months[years[0]][0];

  return {
    years,
    months,
    firstItem,
  };
}

function TimeLine() {
  const { years, months, firstItem } = prepareDara(timelineEvent);
  const [selectedItem, setSelectedItem] = useState("");

  useEffect(() => {
    firstItem && setSelectedItem(firstItem);
  }, []);

  const handleClick = (month) => (e) => setSelectedItem(month);

  const handleScroll = (e) => setSelectedItem(e && e.id);

  const renderLeftSection = ({ years, months }) => {
    const monthsArr =
      months &&
      reduce(values(months), (prev, current) => {
        return prev.concat(current);
      });

    return (
      <div className="tab-section-left">
        <Scrollspy
          offset={0}
          items={monthsArr}
          rootEl=".tab-section-right"
          onUpdate={(e) => handleScroll(e)}
          componentTag="div">
          {map(years, (year, index) => {
            return (
              <div key={index}>
                <div className="tab-timeline-title">{year}</div>
                {map(months[year], (month, index) => {
                  const isActiveItem = classnames("timeline-item", {
                    "timeline-item-active":
                      month === selectedItem ? true : false,
                  });
                  return (
                    <div
                      className={isActiveItem}
                      onClick={handleClick(month)}
                      key={index}>
                      <a href={`#${month}`} className="timeline-box">
                        <div className="timeline-circle"></div>
                        <div className="timeline-label">{month}</div>
                      </a>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </Scrollspy>
      </div>
    );
  };

  const renderRightSection = ({ years, months }) => {
    return (
      <div className="tab-section-right">
        {map(
          sortBy(years, (year) => -year),
          (year) => {
            return (
              <Fragment key={year}>
                {map(months[year], (month) => {
                  return (
                    <Fragment key={month}>
                      <div className="tab-timesection-title" id={month}>
                        {month}
                      </div>
                      {map(get(timelineEvent, month), (item, index) => {
                        return (
                          <div className="tab-timesection-item" key={index}>
                            <Media
                              title={`${month}-${index}`}
                              description={get(item, "event.description")}
                              avatarSrc={get(item, "picture")}
                            />
                          </div>
                        );
                      })}
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          }
        )}
      </div>
    );
  };

  return (
    <div className="tab-container timeline-container">
      {renderLeftSection({ years, months })}
      {renderRightSection({ years, months })}
    </div>
  );
}

export default TimeLine;
