import React from 'react';
import StudentPage from './studentPage';
import TeacherPage from './teacherPage';
import { userInfo } from '../../atom';
import { useRecoilState } from 'recoil';
import { USER } from '../../constant/index'

import './style.less';

function MyWork(props) {
  const [user, setUser] = useRecoilState(userInfo);
  const renderPage = () => {
    if (user.userType === USER.STUDENT) {
      return (
        <div className="student-works-container">
          <StudentPage user={user}/>
        </div>
      );
    }
    return (
      <div className="teacher-works-container">
        <TeacherPage />
      </div>
    );
  };
  return (
    <div className="my-work">
      {renderPage()}
    </div>
  );
}

export default MyWork;
