import React from 'react';
import classnames from 'classnames';

import Button from '../Button';

import './full-media.less';

import SmallAvatarImg from '../../images/small-avatar.png';

function FullMedia({ title, description, avatarSrc=SmallAvatarImg, handleClick}) {
  return (
    <div onClick={(args) => handleClick && handleClick(args)} className="full-media">
      <div className="avatar">
        <img src={avatarSrc} />
      </div>
      <div className="summary">
        <div className="title">{title}</div>
        <div className="description">{description}</div>
      </div>
      <Button color="primary" className="btn-outline">View</Button>
    </div>
  );
}

export default FullMedia;
