import React, { useState, useEffect } from 'react';
import { Card } from '../../components/Card';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import { liveState } from '../../atom';
import FullMedia from '../../components/Media/FullMedia';
import axios from 'axios';

import { APIS, DEV_PATH } from '../../services/api';
function Course() {
  const [liveInfo, setLiveInfo] = useRecoilState(liveState);
  const [learningPathList, setLearningPathList] = useState([]);
  let history = useHistory();
  useEffect(() => {
       // when no path id it will get all course
    requestCourseList('1394908367510048769');
  }, []);
  const changeRoute = (id, coursePackage, ifUpload, courseName, cover) => {
    history.push({pathname:'/curriculum-detail', state:{id, coursePackage, ifUpload, courseName, cover}});
    setTimeout(function () {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      // scrollToAnchor('curriculum-detail')
    }, 500);
  };

  const requestCourseList = (id) => {
    axios({
      method: 'GET',
      url: APIS.QUERY_COURSE_LIST,
      // params: {
      //   pathId: id,
      // },
    })
      .then((res) => {
        const data = res.data.resp;
        data.length ? setLearningPathList(data) : setLearningPathList([]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderLiveContent = () => (
    <div className="live-content">
      <FullMedia
        handleClick={() => handleClickMediaDetail(items.id)}
        description="Description"
        title={liveInfo.data.liveCourseDescribe}
        image={liveInfo.data.cover}
      />
    </div>
  );

  return (
    <div className="course-content">
      {/* {liveInfo.data.pullLiveUrl.length > 0 && renderLiveContent()} */}
      <section className="course-container">
        {
          learningPathList.map((items, index)=> {
            return (
              <Card
                flexStyle
                key={index}
                title={items.courseName}
                description={items.courseDescribe}
                date={moment(Number(items.createTime)).format('YYYY/MM/DD')}
                image={items.cover}
                handleClick={() => changeRoute(items.id, items.coursePackage, items.ifUpload, items.courseName, items.cover)}
              />
            )
          })
        }
      </section>
    </div>
  );
}

export default Course;
