import React, { useRef, useEffect, useState } from 'react';
import { withRouter } from "react-router";

import AppLayout from '../AppLayout';
import WrappedGui from '../../../playground/WrappedGui';

import './style.less';

function Scratch(props) {
  let worksJumpingQueryParams = props.location?.query?.worksItem;
  useEffect(() => {
    const app = document.querySelector('.app');

    if (window.location.pathname.includes('scratch')) {
      app.classList.add('scratch-container');
    }

    return () => {
      app.classList.remove('scratch-container');
    };
  }, []);

  //   const ref = useRef();
  //   const [component, setComponent] = useState(null);
  //
  //   useEffect(() => {
  //     // player = videojs(playerRef.current, { ...props, fluid: true });
  //     setComponent(<Scratch appTarget={ref.current} />);
  //     return () => {
  //       // player.dispose();
  //     }
  // }, []);

  return (
    <AppLayout>
      <div className="container">
        <div className="scratch">
          <WrappedGui worksJumpingQueryParams={worksJumpingQueryParams} />
        </div>
      </div>
    </AppLayout>
  );
}

export default withRouter(Scratch);
