import React, { useState, useEffect, Fragment } from 'react';
import './style.less';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { APIS, DEV_PATH } from '../../../services/api';
import { toast } from 'react-toastify';
import LearningPathItem from './learningPathItem';
import LearningPathItemDetail from './learningPathItemDetail';
import ConfirmModal from '../../../components/Modal/ConfirmModal.js'
function LearningPath() {
    const history = useHistory();

    const [learningPathList, setLearningPathList] = useState([]);
    const [selectedLearningPathItem, setSelectedLearningPathItem] = useState({});
    const [selectedLearningPathItemIndex, setSelectedLearningPathItemIndex] = useState(undefined);
    const [selectedLearningPathDetailItems, setSelectedLearningPathDetailItems] = useState({});
    const [currentId, setCurrentId] = useState('');
    const userInfo = JSON.parse(localStorage.getItem('LP_PORTAL_USER'));
    const [confirmStatus, setConfirmStateus] = useState(false);

    useEffect(() => {
        onInit();
    }, []);

    const onInit = () => {
        requestLearningPathList();
    };
    const requestLearningPathList = () => {
        const URL = `${DEV_PATH}${APIS.QUERY_COLLENT_LEARN_PATH}`;
        axios({
            method: 'GET',
            url: URL,
            params: {
              staffId: userInfo.staffId,
              page: 1,
              limit: 99999
            },
        })
            .then((res) => {
              setLearningPathList(res.data.page.list);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const requestCourseList = (id) => {
        axios({
            method: 'GET',
            url: APIS.QUERY_COURSE_LIST,
            params: {
                pathId: '1394908367510048769',
            },
        })
            .then((res) => {
                setSelectedLearningPathDetailItems(res.data.resp);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const learningPathItemOnClick = (item, itemIndex) => {
        setSelectedLearningPathItem(item);
        setSelectedLearningPathItemIndex(itemIndex);
        requestCourseList(item.id);
    };
    const learningPathDetailItemOnClick = (detailItem) => {
        history.push({ pathname: '/curriculum-detail', state: { id: detailItem.id, coursePackage: detailItem.coursePackage } });
        setTimeout(function () {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 500);
    };

    const deleteLearnPath = (id) => {
      setConfirmStateus(true);
      setCurrentId(id);
    };

    const handleConfirmDel = () => {
      axios({
        method: 'GET',
        url: APIS.COLLECT_LEARN_PATH,
        params: {
          pathId: currentId,
          status: 0,
          staffId: userInfo.staffId
        },
      })
        .then((res) => {
          toast.success("删除成功");
          requestLearningPathList();
          setConfirmStateus(false);
        })
        .catch((error) => {
          console.log(error);
        });

    };
  console.log('learningPathList', learningPathList);
  return (
        <div className="learningPathContainer">
            {learningPathList &&
                learningPathList.length > 0 &&
                learningPathList.map((item, index) => {
                    return (
                        <Fragment key={item.id}>
                            <LearningPathItem
                              item={item}
                              selectedLearningPathItem={selectedLearningPathItem}
                              itemIndex={index}
                              deleteLearnPath={deleteLearnPath}
                              learningPathItemOnClick={learningPathItemOnClick}/>
                            {index % 2 === 1 && (
                                <LearningPathItemDetail
                                    learningPathItemDetailId={Math.floor(index / 2)}
                                    selectedLearningPathItemIndex={selectedLearningPathItemIndex}
                                    selectedLearningPathDetailItems={selectedLearningPathDetailItems}
                                    learningPathDetailItemOnClick={learningPathDetailItemOnClick}
                                />
                            )}
                        </Fragment>
                    );
                })}
            {learningPathList && learningPathList.length > 0 && learningPathList.length % 2 === 1 && (
                <LearningPathItemDetail
                    learningPathItemDetailId={Math.floor((learningPathList.length - 1) / 2)}
                    selectedLearningPathItemIndex={selectedLearningPathItemIndex}
                    selectedLearningPathDetailItems={selectedLearningPathDetailItems}
                    learningPathDetailItemOnClick={learningPathDetailItemOnClick}
                />
            )}
            <ConfirmModal isOpen={confirmStatus} cancelOrClose={setConfirmStateus} handleDel={handleConfirmDel}/>
        </div>
    );
}

export default LearningPath;
