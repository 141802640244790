import React, { Fragment, useState, useEffect } from "react";
import AppLayout from "../AppLayout";
import Section from "../../components/Section";
import VideoPlayer from "../../components/VideoPlayer";
import Recommend from "./Recommend";
import axios from 'axios';
import {APIS} from '../../services/api';
import { FormattedMessage } from 'react-intl';
import UploadCourseWorkModal from '../../components/Modal/UploadCourseWorkModal';
import "./style.less";


function CurriculumDetail(props) {
  const { location } = props;
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [videoList, setVideoList] = useState([]);
  const [courseId, setCourseId] = useState(location.state.id);
  const [ifUpload, setIfUpload] = useState(location.state.ifUpload);
  const [courseCover, setCourseCover] = useState(location.state.cover);
  const [courseName, setCourseName] = useState(location.state.courseName);
  const [uploadWorkState, setUploadWorkState] = useState(false);

  const uploadWork = (e) => {
    e.preventDefault();
    setUploadWorkState(true);
  };

  const handleModalClose = (e) => {
    setUploadWorkState(false);
  };

  useEffect(() => {
    getCourseDetail(courseId)
  }, []);

  const handleVideoClick = (index) => (e) => {
    setCurrentVideoIndex(index);
  };

  const getCourseDetail = (id) => {
    axios({
      method: 'GET',
      url: APIS.GET_COURSE_DETAIL,
      params: {
        courseId: id,
      },
    })
      .then((res) => {
        const response = res.data.resp;
        setVideoList(response)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderVideoList = (list) => {
    return list.map((item, index) => {
      return (
        <div
          key={index}
          className={index === currentVideoIndex ? "video-item selected" : "video-item"}
          onClick={handleVideoClick(index)}>
          <div>
            <i className="fas fa-compact-disc"></i>
          </div>
          {item.courseDetailName}
        </div>
      );
    });
  };

  return (
    <AppLayout>
      <Section title={<FormattedMessage id="curriculum.course" />} className="curriculum-detail">
        <div className="section-content" id="curriculum-detail">
          <div className="video">
            {videoList &&
              videoList.map((item, index) => {
                return (
                  <div
                    style={{
                      display: index === currentVideoIndex ? "block" : "none",
                    }} key={index}>
                    <VideoPlayer
                      autoplay={false}
                      controls={true}
                      sources={[
                        {
                          src: `${item.courseDetailUrl}`,
                          type: "video/mp4",
                        },
                      ]}
                    />
                  </div>
                );
              })}
          </div>
          <div className="list">{renderVideoList(videoList)}</div>
        </div>
        <div className="toolbar">
          <div className="download">
            <a href={location.state.coursePackage} download="sourse code">
              <i className="icon icon-cloud"></i>
              <span>
                <FormattedMessage id="curriculum.download" />
              </span>
            </a>
          </div>
          {ifUpload === 1 && <div className="upload">
            <a href='#' onClick={uploadWork} upload="sourse code">
              <i className="icon icon-upload"></i>
              <span>
                <FormattedMessage id="curriculum.uploadWork" />
              </span>
            </a>
          </div>}
        </div>
      </Section>
      {/*<Recommend />*/}
      <UploadCourseWorkModal
        uploadWorkState={uploadWorkState}
        onModalClose={handleModalClose}
        setUploadWorkState={setUploadWorkState}
        courseName={courseName}
        courseCover={courseCover}
      />
      </AppLayout>
  );
}

export default CurriculumDetail;
