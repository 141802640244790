import videoImgPath_1 from "../images/course-video-image/course-1.png";
import videoImgPath_2_1 from "../images/course-video-image/course-2-01.png";
import videoImgPath_2_2 from "../images/course-video-image/course-2-02.png";
import videoImgPath_2_3 from "../images/course-video-image/course-2-03.png";
import videoImgPath_2_4 from "../images/course-video-image/course-2-04.png";
import videoImgPath_2_5 from "../images/course-video-image/course-2-05.png";
import videoImgPath_2_6 from "../images/course-video-image/course-2-06.png";
import videoImgPath_2_7 from "../images/course-video-image/course-2-07.png";
import videoImgPath_2_8 from "../images/course-video-image/course-2-08.png";
import videoImgPath_2_9 from "../images/course-video-image/course-2-09.png";
import videoImgPath_2_10 from "../images/course-video-image/course-2-10.png";
const DOWNLOAD_URL = "../resources/codes/myaccountbook.sb3";
const VIDEO_LINK_BASE = 'https://littleprogrammer-portal.oss-cn-hangzhou.aliyuncs.com/resources/course_video';
// https://littleprogrammer-portal.oss-cn-hangzhou.aliyuncs.com/resources/homepage.mp4?versionId=CAEQGxiBgMCK5tbgqxciIDYzMTI3NTI2MDNhMzRmNzU5ZmU2MTdiYTA4NmJhNDUy

const VIDEO_LIST_MAIN_INTRO = `https://littleprogrammer-portal.oss-cn-hangzhou.aliyuncs.com/resources/homepage.mp4?versionId=CAEQGxiBgMCK5tbgqxciIDYzMTI3NTI2MDNhMzRmNzU5ZmU2MTdiYTA4NmJhNDUy`;

const VIDEO_LIST = [
  {
    name: "第一课（认识编程）",
    src: `${VIDEO_LINK_BASE}/session1/s1.mp4?versionId=CAEQGxiBgMDe5tbgqxciIDBkNzM4MGYwYjgxNDQ0OGZiOTVlODYwOWYzY2Y1MzUx`,
    poster: videoImgPath_1,
  },
  {
    name: "第二课 - 01(了解Scratch)",
    src: `${VIDEO_LINK_BASE}/session2/s2-01.mp4?versionId=CAEQGxiBgMDb79bgqxciIGM1ZjYwOGFkOWQzODQ4Y2Y4ZDVkNDg1NGMyZDU1YTIx`,
    poster: videoImgPath_2_1,
  },
  {
    name: "第二课 - 02(创建变量)",
    src: `${VIDEO_LINK_BASE}/session2/s2-02.mp4?versionId=CAEQGxiBgICs89bgqxciIDFjNDJmZjE0NTdiNjRhZWU5MjVjZTcwY2VmM2I3MmU2`,
    poster: videoImgPath_2_2,
  },
  {
    name: "第二课 - 03(创建列表)",
    src: `${VIDEO_LINK_BASE}/session2/s2-03.mp4?versionId=CAEQGxiBgIC99tbgqxciIDk4Yzk4Y2Q1Njk2NjQ5YTZhZGQzYThkNTg2NDFiZjM4`,
    poster: videoImgPath_2_3,
  },
  {
    name: "第二课 - 04(编辑角色)",
    src: `${VIDEO_LINK_BASE}/session2/s2-04.mp4?versionId=CAEQGxiBgMCM.tbgqxciIGNmODAwOTJiMjNjZDRiMTBiNjc0N2EzNjJlN2VlODdi`,
    poster: videoImgPath_2_4,
  },
  {
    name: "第二课 - 05(创建日期)",
    src: `https://littleprogrammer-portal.oss-cn-hangzhou.aliyuncs.com/resources/course_video/session2/s2-05.mp4?versionId=CAEQGxiBgMCw_tbgqxciIGYxOTBkZGYyZTFlNDQxNDNiZWZlZTJkYWFkZmQ0ODEw`,
    poster: videoImgPath_2_5,
  },
  {
    name: "第二课 - 06(初始变量)",
    src: `https://littleprogrammer-portal.oss-cn-hangzhou.aliyuncs.com/resources/course_video/session2/s2-06.mp4?versionId=CAEQGxiBgMDvgtfgqxciIDg3OTc5YWQwNDdhMDQwZDJhYjU4NDVkYWZlYjEyNzA1`,
    poster: videoImgPath_2_6,
  },
  {
    name: "第二课 - 07(创建事件-收入1)",
    src: `${VIDEO_LINK_BASE}/session2/s2-07.mp4?versionId=CAEQGxiBgMDVhtfgqxciIDc2YTRjNzc5MTY4ZTRjNGU4MmI2ODJjMWFlN2FkZjdh`,
    poster: videoImgPath_2_7,
  },
  {
    name: "第二课 - 08(创建事件-收入2)",
    src: `${VIDEO_LINK_BASE}/session2/s2-08.mp4?versionId=CAEQGxiBgMDVi9fgqxciIDk1YmZkMjRiNTFiZjQ0MGM5MmZhNWFjZTMxMjY5M2Uw`,
    poster: videoImgPath_2_8,
  },
  {
    name: "第二课 - 09(添加键盘指令)",
    src: `${VIDEO_LINK_BASE}/session2/s2-09.mp4?versionId=CAEQGxiBgICzmdfgqxciIDZiYzk1YmRmNDc5MDRkMTRhNTQzMzc3ZDk3MTdmYzZi`,
    poster: videoImgPath_2_9,
  },
  {
    name: "第二课 - 10（进行运算）",
    src: `${VIDEO_LINK_BASE}/session2/s2-10.mp4?versionId=CAEQGxiBgICDrNfgqxciIDQyMzZmNzlmNGM1MTRhZTY4NzhlOTZkYWM1NTk3MjQz`,
    poster: videoImgPath_2_10,
  },
];

// const VIDEO_LIST = [
//   {
//     name: "第一课（认识编程）",
//     src: "../resources/course_video/session1/s1.mp4",
//     poster: videoImgPath_1,
//   },
//   {
//     name: "第二课 - 01(了解Scratch)",
//     src: "../resources/course_video/session2/s2-01.mp4",
//     poster: videoImgPath_2_1,
//   },
//   {
//     name: "第二课 - 02(创建变量)",
//     src: "../resources/course_video/session2/s2-02.mp4",
//     poster: videoImgPath_2_2,
//   },
//   {
//     name: "第二课 - 03(创建列表)",
//     src: "../resources/course_video/session2/s2-03.mp4",
//     poster: videoImgPath_2_3,
//   },
//   {
//     name: "第二课 - 04(编辑角色)",
//     src: "../resources/course_video/session2/s2-04.mp4",
//     poster: videoImgPath_2_4,
//   },
//   {
//     name: "第二课 - 05(创建日期)",
//     src: "../resources/course_video/session2/s2-05.mp4",
//     poster: videoImgPath_2_5,
//   },
//   {
//     name: "第二课 - 06(初始变量)",
//     src: "../resources/course_video/session2/s2-06.mp4",
//     poster: videoImgPath_2_6,
//   },
//   {
//     name: "第二课 - 07(创建事件-收入1)",
//     src: "../resources/course_video/session2/s2-07.mp4",
//     poster: videoImgPath_2_7,
//   },
//   {
//     name: "第二课 - 08(创建事件-收入2)",
//     src: "../resources/course_video/session2/s2-08.mp4",
//     poster: videoImgPath_2_8,
//   },
//   {
//     name: "第二课 - 09(添加键盘指令)",
//     src: "../resources/course_video/session2/s2-09.mp4",
//     poster: videoImgPath_2_9,
//   },
//   {
//     name: "第二课 - 10（进行运算）",
//     src: "../resources/course_video/session2/s2-10.mp4",
//     poster: videoImgPath_2_10,
//   },
// ];

const FEEDS_DATA = [
  {
    avatar: "",
    name: "Christine Graves",
    date: "15 mins ago",
    description:
      "Majority of peole will work in jobs that don’t exist today.Majority of peole will work in jobs that don’t exist today. Majority of peole will work in jobs that don’t exist today.Majority of peole will work in jobs that don’t exist today. Majority of peole will work in jobs that don’t exist today.Majority of peole will work in jobs that don’t exist today. Majority of peole will work in jobs that don’t exist today.Majority of peole will work in jobs that don’t exist today. Majority of peole will work in jobs that don’t exist today.Majority of peole will work in jobs that don’t exist today. Majority of peole will work in jobs that don’t exist today. Majority of peole will work in jobs that don’t exist today. Majority of peole will work in jobs that don’t exist today. Majority of peole will work in jobs that don’t exist today. Majority of peole will work in jobs that don’t exist today.Majority of peole will work in jobs that don’t exist today. Majority of peole will work in jobs that don’t exist today.Majority of peole will work in jobs that don’t exist today. Majority of peole will work in jobs that don’t exist today.Majority of peole will work in jobs that don’t exist today.",
    images: [],
  },
  {
    avatar: "",
    name: "Anna",
    date: "1 hours ago",
    description:
      "Majority of peole will work in jobs that don’t exist today.Majority of peole will work in jobs that don’t exist today. Majority of peole will work in jobs that don’t exist today.Majority of peole will work in jobs that don’t exist today. ",
    images: [],
  },
  {
    avatar: "",
    name: "Simi",
    date: "1 hours 30 min ago",
    description:
      "Majority of peole will work in jobs that don’t exist today.Majority of peole will work in jobs that don’t exist today. Majority of peole will work in jobs that don’t exist today.Majority of peole will work in jobs that don’t exist today.",
    images: [],
  },
];

export { VIDEO_LIST_MAIN_INTRO, VIDEO_LIST, DOWNLOAD_URL, FEEDS_DATA };
