import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {Autoplay} from 'swiper/core';
import 'swiper/swiper.less';
import AppLayout from "../AppLayout";
import './style.less';
import Banner from './img/banner.png';
import page1 from './img/page1.png';
import page2 from './img/page2.png';
import page3 from './img/page3.png';
import slide1 from './img/slide1.jpg';
import slide2 from './img/slide2.jpg';
import slide3 from './img/slide3.jpg';

function About() {
  SwiperCore.use([Autoplay]);
  function renderSwiper() {
    return (
      <Swiper slidesPerView={2}
              centeredSlides={true}
              autoplay={{
                "delay": 3000,
                "disableOnInteraction": false
              }}
              loop={true}
              className="mySwiper">
        <SwiperSlide>
          <img src={slide1} alt=""/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide2} alt=""/>
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide3} alt=""/>
        </SwiperSlide>
      </Swiper>
    )
  }
  return (
    <AppLayout>
      <div className="about-us">
        <div className="title">
          <p>关于“汇丰小小编程家”<br/>“Little Programmer in HSBC”</p>
        </div>
        <div className="banner">
          <img src={Banner} alt=""/>
        </div>
        <div className="paragraph-container">
          <div className="paragraph">
            <img src={page1} alt=""/>
            <p>汇丰小小编程家由汇丰软件开发（广东）有限公司公益团队开发，是一个公益类编程学习平台，免费向汇丰资助的学校及个人开放。此项目起源于汇丰科技2019年线下公益活动—“汇丰科技希望电教室”，并在汇丰科技中国Angel Fund（天使基金）支持下延伸至线上汇丰小小编程家公益平台。</p>
          </div>
          <div className="paragraph paragraph2">
            <p>本平台专注为6-11岁青少儿提供在线编程课程，通过调动汇丰科技志愿者同事积极参与到平台内容贡献，以及创意讲解让小朋友们体会到编程的乐趣，激发他们的编程梦。</p>
            <img src={page2} alt=""/>
          </div>
          <div className="paragraph">
            <img src={page3} alt=""/>
            <p> “汇丰科技希望电教室”是汇丰科技与陕西省共青团省青少年发展与交流中心合作公益项目，经过3年深度合作以及与相关受益学校及学生接触中，我们充分了解到大家对于公益的诉求。汇丰科技及陕西省团省委均认为，教育对于青少年的发展至关重要，只有教育做好了，才能可持续发展。教育作为汇丰集团可持续发展业务中的一项重要工作，需要我们付出长期不断的投入及努力才有可能见到成效。</p>
          </div>
        </div>
        <div className="carousel">
          {renderSwiper()}
        </div>
      </div>
    </AppLayout>
  );
}

export default About;
